import { matchPath } from 'react-router-dom';
import { ROUTE_META } from '../RouteMeta';

const { employer, advisor, tpa, psivet, onedigital } = ROUTE_META;

export const rootRoute = (path) =>
  matchPath(path, {
    path: [
      employer.route,
      advisor.route,
      tpa.route,
      psivet.route,
      onedigital.route,
    ],
  })?.path || employer.route;

export const getUrlParameter = (name) => {
  const reName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); // eslint-disable-line no-useless-escape
  const regex = new RegExp(`[\\?&]${reName}=([^&#]*)`);
  let results = '';
  if (typeof window !== 'undefined') {
    results = regex.exec(window.location.search);
  }
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const googleData = (key) => ({
  event: 'PlanDesignView',
  PlanDesignView: key,
});

export const asOfDate = 'March 31, 2024';
export const investmentCost = '0.03% - 0.95%';
export const oneDigInvestmentCost = '0.03% - 0.86%';

export const pushGrandfatherDateQuestions = (questions, role, planArray) => {
  const {
    hasFp,
    hasTpa,
    hasGrandfatherDate,
    grandfatherDateHasCustomDate,
    grandfatherDate,
  } = questions;
  if (hasTpa === undefined && hasFp === undefined) return;
  switch (role) {
    case 'psivet':
    case 'onedigital':
    case 'employer':
      if (hasTpa.value === false && hasFp.value === false) {
        planArray.push(hasGrandfatherDate);
        if (hasGrandfatherDate.value === 'yes') {
          planArray.push(grandfatherDateHasCustomDate);
          if (grandfatherDateHasCustomDate.value === 'yes') {
            planArray.push(grandfatherDate);
          }
        }
      }
      break;
    case 'advisor':
      if (hasTpa.value === false) {
        planArray.push(hasGrandfatherDate);
        if (hasGrandfatherDate.value === 'yes') {
          planArray.push(grandfatherDateHasCustomDate);
          if (grandfatherDateHasCustomDate.value === 'yes') {
            planArray.push(grandfatherDate);
          }
        }
      }
      break;
    case 'tpa':
      planArray.push(hasGrandfatherDate);
      if (hasGrandfatherDate.value === 'yes') {
        planArray.push(grandfatherDateHasCustomDate);
        if (grandfatherDateHasCustomDate.value === 'yes') {
          planArray.push(grandfatherDate);
        }
      }
      break;
    default:
      break;
  }
};
