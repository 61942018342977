import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import HeaderSection from '../../../common/HeaderSection';
import { PrincipalTabs, Item } from '../../../common/PrincipalTabs';
import TabContent from './TabContent';
import { ResourcesHeroBusinessOwner, CheckCircle } from '../../../ImagePaths';

const sectionHeader = {
  image: ResourcesHeroBusinessOwner,
  title: 'Resources for small business clients',
  caption: `
    <p class="fs-display5">
      Administering plans and servicing client needs is a full-time job. Save time with these resources to help your small business clients better understand the ins and outs of workplace retirement plans.
    </p>
  `,
  alt: 'Employee reading article in newspaper.',
  copy: ``,
};

const tabsSmallBiz = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Small business retirement trends and insights
    </h3>
    <p class="fs-h2 mb-5">
      Inform your clients about the latest industry trends and what to expect when it comes to retirement plans and their businesses.
    </p>
  `,
  resourceCards: [
    {
      id: 0,
      headline:
        '3 things every small business owner needs to know about employee retirement',
      date: 'Jan 1, 2020',
      link: 'https://www.myubiquity.com/business/small-business-owner-employee-retirement-knowledge/',
    },
  ],
};

const tabsChoosingPlan = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Choosing and designing a retirement plan
    </h3>
    <p class="fs-h2 mb-5">
      Here are some things to consider as you evaluate your options and
      design a retirement plan for your business.
    </p>
  `,
  resourceCards: [
    {
      id: 0,
      headline: 'Guide to finding the right retirement plan',
      date: 'April, 2020',
      link: 'http://www.myubiquity.com/wp-content/uploads/2019/08/Ubiquity_401kGuide.ok2_2019-1.pdf',
    },
    {
      id: 1,
      headline: 'Guide to safe harbor 401(k)',
      date: 'April, 2020',
      link: 'https://www.myubiquity.com/401k/safe-harbor-401k/',
    },
    {
      id: 2,
      headline: 'Your small business is the right size for a 401(k)',
      date: 'March, 2020',
      link: 'https://www.myubiquity.com/401k-plan-information/small-business-big-benefits/',
    },
    {
      id: 3,
      headline: 'Choosing a retirement solution for your small business',
      date: 'March, 2020',
      link: 'https://www.dol.gov/sites/dolgov/files/ebsa/about-ebsa/our-activities/resource-center/publications/choosing-a-retirement-solution-for-your-small-business.pdf',
    },
    {
      id: 4,
      headline:
        'Why offer a competitive retirement plan? A resource for plan sponsors',
      date: 'March, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12337T&ty=VOP&EXT=.VOP',
    },
    {
      id: 5,
      headline: 'What’s a safe harbor 401(k) plan and how is it different?',
      date: 'March, 2020',
      link: '/safeharbor-explained',
      linkText: 'Visit Link',
    },
  ],
};

const tabsMangingEffect = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Managing an effective retirement plan
    </h3>
    <p class="fs-h2 mb-5">
      Find out what you need to know as the plan sponsor to manage this
      important benefit for your employees.
    </p>
  `,
  resourceCards: [
    {
      id: 0,
      headline: 'What best-in-class retirement plan sponsors do differently',
      date: 'April, 2020',
      link: 'https://www.principal.com/employers/trends-insights/what-best-class-retirement-plan-sponsors-do-differently',
    },
  ],
};

const tabsPlanResources = {
  intro: `
    <h3 class="fs-h3 text-purple">
      401(k) plan resources
    </h3>
    <p class="fs-h2 mb-5">
      Find out what you need to know as the plan sponsor to manage this
      important benefit for your employees.
    </p>
  `,
  resourceCards: [
    {
      id: 0,
      headline: 'What best-in-class retirement plan sponsors do differently',
      date: 'April, 2020',
      link: 'https://www.principal.com/employers/trends-insights/what-best-class-retirement-plan-sponsors-do-differently',
    },
  ],
};

const tabsResources = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Resources for your employees
    </h3>
    <p class="fs-h2 mb-5">
      Share these resources with your employees to help them prepare for the
      future and maximize their savings.
    </p>
  `,
  resourceCards: [
    {
      id: 0,
      headline: 'Traditional 401(k) vs Roth 401(k)',
      date: 'April, 2020',
      link: 'https://www.myubiquity.com/401k-resources/traditional-401k-vs-roth-401k/',
    },
    {
      id: 1,
      headline: 'Is it a good idea to take out a 401(k) loan?',
      date: 'April, 2020',
      link: 'https://www.myubiquity.com/personal-finance/thinking-about-taking-401k-loan/',
    },
    {
      id: 2,
      headline: 'Millennials: take these steps to prepare for retirement',
      date: 'March, 2020',
      link: 'https://www.myubiquity.com/retirement-trends/millennials-take-these-steps-to-prepare-for-retirement/',
    },
    {
      id: 3,
      headline: 'Signing up for your first retirement plan',
      date: 'March, 2020',
      link: 'https://www.myubiquity.com/personal-finance/getting-schooled-on-retirement/',
    },
  ],
};

const Resources = () => (
  <div className="resources-page mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
    />
    <section
      className="section position-relative"
      style={{ marginTop: '-70px', zIndex: '1' }}
    >
      <div className="container-xl px-0">
        <PrincipalTabs panelStyle="default">
          <Item
            title="Small business retirement trends and insights"
            component={
              <TabContent
                intro={tabsSmallBiz.intro}
                resourceCards={tabsSmallBiz.resourceCards}
              />
            }
          />
          <Item
            title="Choosing and designing a retirement plan"
            component={
              <TabContent
                intro={tabsChoosingPlan.intro}
                resourceCards={tabsChoosingPlan.resourceCards}
              />
            }
          />
          <Item
            title="Managing an effective retirement plan"
            component={
              <TabContent
                intro={tabsMangingEffect.intro}
                resourceCards={tabsMangingEffect.resourceCards}
              />
            }
          />
          <Item
            title="401(k) plan resources"
            component={
              <TabContent
                intro={tabsPlanResources.intro}
                resourceCards={tabsPlanResources.resourceCards}
              />
            }
          />
          <Item
            title="Resources for employees"
            component={
              <TabContent
                intro={tabsResources.intro}
                resourceCards={tabsResources.resourceCards}
              />
            }
          />
        </PrincipalTabs>
      </div>
    </section>
    <section className="section py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <h3 className="fs-h3 text-center mb-5">
              See what Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              offers you.
            </h3>
          </div>
        </div>
        <div className="row d-print-none">
          <div className="col-lg-7 mx-auto">
            <p className="fs-h6 text-center mb-5">
              Watch this video demo and see how we make it easy to design and
              manage retirement plans for small businesses.
            </p>
            <ReactPlayerLoader
              accountId="1510329453001"
              videoId="6274775432001"
              attrs={{
                className: 'brightcove-player',
              }}
              embedOptions={{ responsive: { aspectRatio: '16:9' } }}
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section section_py-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="resource-card text-center px-md-8 py-md-5">
              <p>
                <strong>
                  Get helpful information to support you through the plan design
                  process.
                </strong>{' '}
                Download this document for plan design considerations and other
                important details for TPAs.
              </p>
              <a
                href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP"
                target="_blank"
                className="srp-button srp-button__primary"
              >
                Get plan design support
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section_py-5 pt-5">
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <img
              src={CheckCircle}
              alt="Checkmark In Circle Icon"
              className="d-block mx-auto mb-3"
            />
            <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
            <p className="text-center pb-5 mb-5">
              <Link to="/plans" className="srp-button srp-button__primary">
                Try our 401(k) planner
              </Link>
            </p>
            <p className="fs-disclaimer text-center pb-5 mb-5">
              Intended for TPA and financial professional use.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Resources;
