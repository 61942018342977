import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../../../../../common/FormWithSteps';

const TpaFee = ({ role, questions, handleQuestionChange }) => {
  const { tpaSetupFee, annualTpaFee, annualTpaPerHeadFee } = questions;

  return (
    <fieldset>
      <div className="row">
        <div className="col-lg-6 mx-auto mb-5">
          <p className="text-center">
            The following fees will be collected by the TPA outside of the plan.{' '}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 mx-auto">
          <Question
            role={role}
            type={tpaSetupFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={tpaSetupFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {tpaSetupFee.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 mx-auto">
          <Question
            role={role}
            type={annualTpaFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={annualTpaFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {annualTpaFee.hasError && <small>Question is required</small>}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 mx-auto">
          <Question
            role={role}
            type={annualTpaPerHeadFee.type}
            thousandSeparator
            labelClass="fs-form-label fw-bold"
            inputClass="srp-form-input form-control"
            question={annualTpaPerHeadFee}
            handleQuestionChange={handleQuestionChange}
            allowLeadingZeros
          >
            {annualTpaPerHeadFee.hasError && (
              <small>Question is required</small>
            )}
          </Question>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mx-auto">
          <p>
            *TPA fees are for illustrative purposes only. These fees will not be
            collected by Ubiquity. The TPA firm will disclose their fees and
            bill the plan sponsor directly.
          </p>
        </div>
      </div>
    </fieldset>
  );
};

TpaFee.propTypes = {
  role: PropTypes.string,
  questions: PropTypes.object,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default TpaFee;
