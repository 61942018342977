import React from 'react';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import {
  FaqHeroImage,
  tpaFaqImage1,
  tpaFaqImage2,
  tpaFaqImage3,
  tpaFaqImage4,
  tpaFaqAddDocument1,
  tpaFaqAddDocument2,
  tpaFaqAddDocument3,
} from '../../../ImagePaths';
import NoticesTableSection from './NoticesTableSection';
// import SupportTableSection from './SupportTableSection';
import { asOfDate } from '../../../Services/utilities';

const sectionHeader = {
  image: FaqHeroImage,
  title: 'Frequently  asked questions',
  caption: `
    <p class="fs-display5">
      Looking for more details? Here are answers to some of the questions you may have about Simply Retirement by Principal<sup><small>®</small></sup>.
    </p>
  `,
  alt: 'Man working on a computer.',
  copy: ``,
};

const sectionGeneralInformation = `
<h4 class="fs-h3 my-3 pt-5">
  General Information
</h4>
<p class="fs-h2 text-purple"><strong>What is Simply Retirement by Principal<sup><small>®</small></sup>?</strong></p>
<p class="fs-p mb-4">
    Simply Retirement by Principal<sup><small>®</small></sup> is a product for startup plans with fewer than 100 employees. It’s designed to offer an easy-to-use, fully online experience that removes the friction that can occur with traditional onboarding—including chasing down paperwork, getting answers to service and expense questions, finalizing the investment offering, and selecting a fidelity bond. Our goal is to simplify and streamline this process, so you can focus on delivering value to your clients. Plan setup takes about a week, not 8–10 weeks, and most steps are fully digital and totally automated.
<p class="fs-p mb-4">
    Backed by experience, this product combines more than 75 years of retirement expertise from Principal<sup><small>®</small></sup> with the digital technology and services of Ubiquity Retirement + Savings<sup><small>®</small></sup>. We’ve come together to help make retirement plans a reality for more small businesses.</p>
<p class="fs-p mb-4">
    Note: Simply Retirement by Principal<sup><small>®</small></sup> is available for use by third party administrators (TPAs) beginning Oct. 4, 2021. TPAs have the ability to create plans on their own and/or be included when a financial professional creates a plan.</p>
<p class="fs-h2 text-purple"><strong>Why did Principal create a digital product, and what does this term mean?</strong></p>
<p class="fs-p mb-4">
    When we refer to Simply Retirement by Principal<sup><small>®</small></sup> as a digital product, it means that everything is online, from designing the plan to onboarding and administration.
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    We created a digital product for several reasons. One of our goals was to provide a simple strategy for small businesses to establish a 401(k) plan and help their employees save for retirement—helping to address a need for the thousands of small businesses who don’t currently offer their employees a retirement savings plan. We also wanted to provide a simple plan for TPAs and financial professionals to quote and establish small business retirement plans efficiently and easily—so they can spend less time managing paperwork and more time helping clients navigate the process.
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>Who is Ubiquity and why is Principal<sup><small>®</small></sup> working with them?</strong></p>
<p class="fs-p mb-4">
    Ubiquity is a leading fintech company that was selected over peers because of their tenure and experience in the industry.  A couple key facts about Ubiquity:
    <ul>
        <li>22 years of digital 401(k) plan technology experience—used by more than 10,000 small businesses*.</li>
        <li>Simply Retirement by Principal<sup><small>®</small></sup> uses Paradigm RKS<sup><small>™</small></sup>, Ubiquity’s cloud-based, proprietary recordkeeping system.</li>
    </ul>
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    We were intentional about our choice to work with Ubiquity. After exploring and evaluating a variety of technology options, we selected Ubiquity for its established presence as an innovator in the digital retirement space. Our vision with small businesses starting a 401(k) plan has always been to deliver the best possible digital retirement solution, and Ubiquity’s proprietary recordkeeping system is a forward-thinking, future-ready solution that will allow for growth and enhancements to help meet our customers’ needs in the long term.
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    *As of June 2021.
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>Who is Wilshire Advisors LLC, and how do they fit in?</strong></p>
<p class="fs-p">
    Wilshire & Associates, Inc. will be the plan’s 3(38) investment fiduciary responsible for selecting and monitoring the plan’s investment options. Wilshire Advisors LLC is an independent investment management firm that manages capital for more than 600 institutional investors globally, representing more than $8 trillion of capital.
`;

const sectionCompensationAndFees = `
<h4 class="fs-h3 my-3 pt-5">
  TPA Compensation and Fees
</h4>
<p class="fs-h2 text-purple"><strong>Is Simply Retirement by Principal<sup><small>®</small></sup> part of the Principal TPA Edge<sup><small>SM</small></sup> program?</strong></p>
<p class="fs-p mb-4">
    Simply Retirement by Principal<sup><small>®</small></sup> is not included as part of the Principal TPA Edge program, and there’s no compensation from Principal for establishing the plan. Simply Retirement by Principal<sup><small>®</small></sup> plans will, however, count towards qualifying for TPA Platinum status. Here are some of the differences you may notice with Simply Retirement by Principal<sup><small>®</small></sup> compared to the Principal TPA Edge program:
    <ul>
        <li>You will use a different website and process to get quotes and establish the plan.</li>
        <li>The onboarding transition plan is much shorter and streamlined. A plan can be completed and up and running in as little as a week.</li>
        <li>Ongoing plan administration is different. Simply Retirement by Principal<sup><small>®</small></sup> plans will be managed using the TPA portal on Ubiquity’s website (available 1/1/22).</li>
        <li>Ongoing support is provided by Ubiquity. You can call  855-472-0472 for help.</li>
        <li>Making changes to the plan is efficient. It doesn’t require completing a Plan Provision Checklist (PPC) and pulling in a Client Services Associate at Principal. The process is automated on the Ubiquity TPA portal, meaning less manual processing and paperwork. (available 1/1/22)</li>
    </ul>
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    If you have questions about Simply Retirement by Principal<sup><small>®</small></sup> plan administration and the Simply Retirement by Principal<sup><small>®</small></sup> TPA dashboard, please contact Ubiquity at 855-472-0472.
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>How will the TPA be compensated? Will the TPA compensation options be the same as they are for the Principal platform?</strong></p>
<p class="fs-p mb-4">
    You will bill your fees directly to the plan sponsor. There are potentially three components to your fees that can be included in the Simply Retirement by Principal<sup><small>®</small></sup> fee proposal (if you choose) which will provide more transparency to the business owner and a “view” of the total cost:
    <ul>
        <li>One-time set up fee (billed directly to the employer and collected by the TPA outside of plan assets)</li>
        <li>Annual base fee (billed directly to the employer and collected by the TPA outside of plan assets)</li>
        <li>Annual per head fee (billed directly to the employer and collected by the TPA outside of plan assets)</li>
    </ul>
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    In addition to your fees, Ubiquity will pay the TPA $35 for each participant distribution and loan.
<p class="fs-p mb-4">
<h4 class="fs-h3 my-3 pt-5">
  Plan Fees
</h4>
<p class="fs-h2 text-purple"><strong>What are the plan fees, and who pays them?</strong></p>
<p class="fs-p mb-4">
    The business owner pays:
    <ul style="list-style: none;">
        <li><strong>$145 per month</strong> recordkeeping fee ($435 billed quarterly).</li>
        <li><strong>Financial professional and/or TPA fees</strong> if the business owner chooses to work with one. The TPA will bill the business owner directly for their fees. If the financial professional chooses to be compensated through a flat fee, this fee will be billed to the business owner.</li>
        <li><strong>ERISA Fidelity Bond</strong> For the first three years, Ubiquity Retirement + Savings will cover the cost oof an ERISA fidelity bond up to $250,000 in assets (which equals a $25,000 bond). After three years, a renewal letter will be sent and the business owner can choose to accept the renewal at their own expense, or consult with their financial professional and/or TPA in choosing another bond provider.</li>
    </ul>
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    The participant pays:
    <ul style="list-style: none;">
        <li><strong>$6 per participant,</strong> per month recordkeeping fee for each participant with an account balance over $100. This is deducted from participant accounts monthly. The business owner may elect to pay it quarterly on behalf of the participants.</li>
        <li>If the financial professional chooses to be paid out of plan assets, this fee will be deducted quarterly from participant balances in the plan on a pro-rated basis. (TPA fees must be billed directly to the business owner.)</li>
        <li><strong>Custodial and investment fees,</strong> which are charged against participating employees’ accounts (those vary by investment and range from 0.03% – 0.86%, as of ${asOfDate}). Custodial fees are up to 0.055%.</li>
    </ul>
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>Financial professional fees</strong></p>
<p class="fs-p mb-4">
   Financial professionals (if the business owner chooses to work with one) have the choice of being compensated through a flat fee or basis points as approved through their firm-level rules of engagement.  (Simply Retirement by Principal<sup><small>®</small></sup> includes firm-level rules of engagement through the digital quoting and purchase process). They have the ability to choose 25bp, 50bp or 75bp, or a flat fee not to exceed 50bp annually.
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>What happens if the company grows beyond 100 employees?</strong></p>
<p class="fs-p mb-4">
    Plans with 100 or more employees may be subject to an independent audit requirement. Ubiquity, as the recordkeeper, provides the TPA with access to annual reporting necessary to assist with the TPA and auditor’s efforts.  Special reporting may be subject to Ubiquity’s hourly rate of $195.
<p class="fs-p mb-4">
`;

const sectionPlanDesign = `
<h4 class="fs-h3 my-3 pt-5">
  Creating a plan design/proposal online
</h4>
<p class="fs-h2 text-purple"><strong>Can you provide me a summary of the Simply Retirement by Principal<sup><small>®</small></sup> quoting process?</strong></p>
<p class="fs-p mb-4">
    On the TPA landing page, you will have the option to:
    <ul style="list-style: none;">
        <li class="mb-2"><strong>Create a Plan Proposal</strong> (How do I get a quick pricing proposal?)
            <ul>
                <li>You will be asked to enter in a few details, including the option to add your own fees (one time set-up fee, annual base fee, annual per head fee) and/or logo.  You will not be required to complete the plan design for a quick pricing proposal.</li>
                <li>When you print or email the price quote, you will also receive a <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP">checklist</a> to walk through the plan design features with the business owner.</li>
                <li>When you are ready to complete the plan design, you will navigate back to the simplyretirement.com TPA homepage and select “Create a Plan” from the top navigation.</li>
            </ul>
        </li>
        <li class="mb-2"><strong>Create a Plan</strong> (How do I complete the full proposal with plan design details?)
            <ul>
                <li><strong>If you're working with a financial professional:</strong>
                    <p class="fs-p mb-4">
                        The TPA owns all aspects of the plan design and the financial professional selects the investment lineup and inputs their compensation.  If the TPA initiates the process of creating the plan, they will be asked to provide the financial professional’s name and email. The financial professional will then be prompted to complete the investment selection. The financial professional can then share the plan with the client or send it back to the TPA to present it to the client for approval.
                    <p class="fs-p mb-4">
                    <p class="fs-p mb-4">
                        If the financial professional initiates the process, they will insert their information and selections, then send it to the TPA to insert their information. The TPA can then share the plan with the client or send it back to the financial professional to present it to the client for approval.
                    <p class="fs-p mb-4">
                    <p class="fs-p mb-4">
                        Note: Whether the TPA or the financial professional starts the full proposal, the second party in the process has the option to share it with the client for approval. This capability is not restricted to the party who started the plan (the plan originator).
                    <p class="fs-p mb-4">
                </li>
                <li>Ubiquity Payroll (K)oncierge<sup><small>TM</small></sup> will then assist the plan sponsor in setting up their payroll and will help them with the submission of their first payroll.</li>
                <li>Once a week, the TPA will receive an automated email that shows where each of their Simply Retirement by Principal<sup><small>®</small></sup> plans are in the implementation process.</li>
            </ul>
        </li>
    </ul>
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>What information do I need to create a plan online?</strong></p>
<p class="fs-p mb-4">
    When creating a plan, the TPA owns all aspects of the plan design and the financial professional (if applicable) selects the investment lineup and inputs their compensation. The TPA will have a variety of plan design options available for eligibility, vesting and matching contributions that will allow the recordkeeper, Ubiquity, to provide the day-to-day administrative service aspects of the clients’ plan design. TPAs will need to provide the following data to create a plan through Simply Retirement by Principal<sup><small>®</small></sup>:
    <ul>
        <li>TPA sales contact’s full name and email</li>
        <li>TPA operational contact’s full name, email, phone number and ZIP code</li>
        <li>TPA firm name</li>
        <li>TPA Employer Identification Number (EIN)</li>
        <li>TPA logo for proposal (not required)</li>
        <li>Client contact’s full name and email</li>
        <li>Client company name</li>
        <li>Company state of business</li>
        <li>Client EIN</li>
        <li>Financial professional contact’s full name and email (for investment selection and fees, if applicable)</li>
        <li>TPA setup fee, annual base fee, and annual per head fee to be included on the proposal, if desired (not required)</li>
        <li>Number of employees at client firm that would be eligible for the plan</li>
        <li>Plan design selections around:
            <ul>
                <li>Plan effective date</li>
                <li>Roth contributions</li>
                <li>Eligibility and entry requirements</li>
                <li>Auto enrollment option</li>
                <li>Safe harbor</li>
                <li>ER contributions (if applicable)</li>
                <li>Vesting</li>
                <li>Loans and hardship withdrawals</li>
            </ul>
        </li>
    </ul>
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>How long does it take to answer the questions and create a plan online?</strong></p>
<p class="fs-p mb-4">
    On average, 15 to 20 minutes is a reasonable estimate for creating a plan online, but your experience may vary. There are about 15 questions to answer (there may be a few more, depending on which options are selected).
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    If you close out of the online questions before saving your plan, you’ll see a button at the bottom of your screen that says “You have a plan in progress.” As long you have the website open, you can click this button to pick up where you left off. If you want to leave the site and come back later, you’ll need to finish the questions, save your plan design, and save the link to be able to return and edit your answers. Plan designs are saved for 60 days.
<p class="fs-p mb-4">
<p class="fs-h2 text-purple"><strong>How do I get help if I have a question or need to make changes to the plan?</strong></p>
<p class="fs-p mb-4">
    When you create a Simply Retirement by Principal<sup><small>®</small></sup> plan online and share a link to that plan with either the financial professional or the business owner, the link will be available for 60 days. You can make changes online anytime during that 60 days until the business owner submits the plan for purchase.
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    After the plan is purchased, your Simply Retirement by Principal<sup><small>®</small></sup> plans can be modified by making a request to the Ubiquity administrative team. Change requests may include plan provision changes, corrective refund requests, etc. You can contact Ubiquity via phone or email, or beginning Jan. 1, 2022, you’ll be able to request the changes digitally via the TPA portal through a built-in communication mechanism.
<p class="fs-p mb-4">
<p class="fs-p mb-4">
    If you need help or have a question, you can call the Simply Retirement by Principal<sup><small>®</small></sup> dedicated phone number: 855-700-5569, or email <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a>
<p class="fs-p mb-4">

`;

const sectionPlanFeatures = `
  <h4 class="fs-h3 my-3 pt-5">
    Plan features
  </h4>
  <p class="fs-h2 text-purple">
    <strong>Are the plan design options restricted, and if so, how?</strong>
  </p>
  <p class="fs-p mb-4">
    Yes. As a TPA, you’ll have a variety of plan design options around eligibility, vesting, and matching contributions that will allow the recordkeeper, Ubiquity Retirement + Savings, to provide the day-to-day administrative service aspects of your client’s plan design (saving you time when reviewing participant transactions like distributions and loans).  When completing a quick quote, the TPA will receive a <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736S&ty=VOP">checklist</a> that will outline the plan design flexibility available with Simply Retirement by Principal<sup><small>®</small></sup>.
  </p>
  <p class="fs-p mb-4">
    With Simply Retirement by Principal<sup><small>®</small></sup>, we aim to make the upfront process streamlined and efficient for TPAs with a simple packaged plan. This allows the TPA to focus on their value proposition instead of completing lots of paperwork. Plan setup takes about a week, not 8–10 weeks, and most steps are fully digital and totally automated.
  </p>
  <p class="fs-h2 text-purple">
    <strong>What vesting options are available for plan design?</strong>
  </p>
  <p class="fs-p mb-4">
    The TPA will select the opinion(s) that apply while creating the plan online.
  </p>
  <p class="fs-p mb-4">
    Vesting schedule options include:
    <ul>
        <li>Immediate (100%),</li>
        <li>4-year graded (25%, 50%, 75%, 100%),</li>
        <li>5-year graded (20%, 40%, 60%, 80%, 100%),</li>
        <li>6-year graded (0%, 20%, 40%, 60%, 80%, 100%), and </li>
        <li>3-year cliff (0%, 0%, 100%).</li>
    </ul>
  </p>
  <p class="fs-h2 text-purple">
    <strong>Are loans available with this plan?</strong>
  </p>
  <p class="fs-p mb-4">
    Yes. If the business owner chooses to offer loans, participants can request to take one loan from their 401(k) plan balance and select a loan repayment schedule that best suits them (but is no longer than five years). Participants may repay a loan to up to 30 years if the proceeds are used to purchase their primary residence. Only one loan may be outstanding at a time. Loan repayments are made via after-tax payroll deductions. The interest portion (2 percentage points over the prime interest rate) of the loan payment is applied to the participant’s account.
  </p>
  <p class="fs-h2 text-purple">
    <strong>Do participants have the option to defer Roth contributions with this plan?</strong>
  </p>
  <p class="fs-p mb-4">
    Yes. If selected, business owners may choose to offer participants the ability to make Roth contributions in addition to pre-tax deferrals with this plan.
  </p>
  <p class="fs-h2 text-purple">
    <strong>Are the investment lineup options the same as the Principal platform, and will financial professionals be restricted in what they can use?</strong>
  </p>
  <p class="fs-p mb-4">
    Simply Retirement by Principal<sup><small>®</small></sup> offers three Wilshire 3(38) investment lineups to choose from depending on whether the business owner is working with a financial professional and which investments the financial professional is licensed to offer. For more information, see <a href="https://www.simplyretirement.com/third-party-administrator/investments">this page</a>. There’s a listing of investment options under each asset class and you can click any investment option for fact sheets and other details.
  </p>
  <p class="fs-p mb-4">
    Most clients work with a financial professional. If the TPA initiates the process of creating a Simply Retirement by Principal<sup><small>®</small></sup> plan, you will be asked to provide the financial professional’s first name, last name, and email. When you’re finished making the plan selections online, you will be prompted to share the plan with the financial professional via email. The financial professional will open the link, select the investment lineup, save the plan, and share it back to you via email so you can review and send it to your client for approval and purchase.
  </p>
  <p class="fs-p mb-4">
    In limited instances where the TPA creates a plan and there isn’t a financial professional, the business owner will receive a pre-selected Wilshire 3(38) investment lineup, which is already factored into pricing. This allows you to continue focusing time and effort on your value proposition while utilizing the expertise of Wilshire & Associates to assist your client in selecting an investment lineup for the plan.
  </p>
  <p class="fs-h2 text-purple">
    <strong>Small business growth platform</strong>
  </p>
  <p class="fs-p mb-4">
    With a Simply Retirement by Principal<sup><small>®</small></sup> plan, your client has access to Elevate by Principal, a powerful network, resource, and team of people in their corner. From data-driven insights to deep discounts on products and services, that a business owner uses every day, Elevate by Principal can provide what your client needs to take their business to the next level.
  </p>
`;

const sectionOnBoarding = `
<h4 class="fs-h3 mb-3 pt-5">
  Onboarding
</h4>
<p class="fs-h2 text-purple">
  <strong>What payroll companies will you have integration with and how will they get set up?</strong>
</p>
<p class="fs-p mb-4">
    Ubiquity offers 360-integration for all clients with a cloud-based payroll system through Payroll (K)oncierge<sup><small>TM</small></sup> and Enhanced Payroll services. Please visit <a href="https://www.myubiquity.com/payroll-integration">Payroll Integration - Ubiquity</a> for more information regarding Ubiquity’s payroll services
</p>
<p class="fs-h2 text-purple">
  <strong>Who will help the plan sponsor with the first payroll submission?</strong>
</p>
<p class="fs-p mb-4">
    As part of the implementation process, a Ubiquity team member serves as the Payroll (K)oncierge<sup><small>TM</small></sup> and meets with the business owner to review integration options, provides simple instructions, and assists the plan sponsor with their first payroll submission. There is no additional fee for this service as this is part of the Simply Retirement by Principal<sup><small>®</small></sup> plan sponsor experience.
</p>
<p class="fs-h2 text-purple">
  <strong>How will plan implementation work, and will I still need to complete a Plan Provision Checklist for each plan?</strong>
</p>
<p class="fs-p mb-4">
    Ubiquity follows a 7-step onboarding and implementation process. Each week, you will receive an email showing which stage each of your Simply Retirement by Principal<sup><small>®</small></sup> plans are at in the onboarding process.  Please refer to the detailed implementation guide for more information on the steps, timing, and what you can expect.
</p>
<p class="fs-p mb-4">
    You will not have to complete a Plan Provision Checklist (PPC). We’ve digitized this process to create a more streamlined and efficient onboarding experience resulting in less paperwork for the TPA.  As a result, while many plans require an 8-10 week process to onboard, Simply Retirement by Principal<sup><small>®</small></sup> plans only take about a week or so.
</p>
<p class="fs-h2 text-purple">
  <strong>What happens after the business owner submits the plan for purchase?</strong>
</p>
<p class="fs-p mb-4">
    Once the plan sponsor clicks the “Complete your purchase” on the “Review Your Plan” page, they’ll be redirected to the Ubiquity Retirement + Savings<sup><small>®</small></sup> website, where they will:
    <ul>
        <li>Set up their login credentials.</li>
        <li>Verify and provide details about their business.</li>
        <li>E-Sign required documents.</li>
    </ul>
</p>
<p class="fs-p mb-4">
    After the plan sponsor signs all the required documents, they’ll be able to configure their account to activate their plan:
    <ul>
        <li>Add a contribution funding account.</li>
        <li>Add their company’s payroll schedule(s).</li>
        <li>Complete their application to use ACH for payroll contribution processing.</li>
        <li>With the plan now active, the Payroll (K)oncierge<sup><small>TM</small></sup> will be available to help them successfully enter or upload their employee data (census) and process their first payroll contribution.</li>
    </ul>
</p>
<p class="fs-h2 text-purple">
  <strong>What enrollment support will be provided to eligible employees?</strong>
</p>
<p class="fs-p mb-4">
    Once the plan is set up and employee data (census) is entered onto the website, eligible employees will receive a welcome email from Ubiquity inviting them to enroll in the plan by setting up their online accounts. Participants can use their online account to make deferral elections, select their investments, view their balance, request loans and distributions, and review plan notices and disclosures.
</p>
<p class="fs-p mb-4">
    Participants also receive information regarding the financial wellness program in their welcome email, which will provide guidance regarding how to access the online financial wellness tools and resources. Ubiquity provides online access to a range of instructional videos, webinars, guides, and checklists, including an enrollment kit for employees to assist with the day-to-day management of plan functions. Ubiquity’s Customer Success Team can be reached by phone, email, or chat for additional support.
</p>
<p class="fs-h2 text-purple">
  <strong>What is the participant experience with Simply Retirement by Principal<sup><small>®</small></sup>?</strong>
</p>
<p class="fs-p mb-4">
    Participants will have a simple-to-use online dashboard on the Ubiquity website. As soon as they’re enrolled, they’ll get a welcome email with a link to get started. On their first visit, they’ll set up their online account, view their personal information, and confirm their investment selection. If they’re not sure which investments to select, they can take a brief quiz to see their suggested asset allocation. They can also review their current allocations and make updates. Every time they log in, they’ll see their personal dashboard with their investment balance, deferral percentage and contribution history. If they have questions, they can check out the resources, tools, and frequently asked questions.
</p>
`;

const sectionPlanAdministration = `
<h4 class="fs-h3 mb-3 pt-5">
    Plan administration on the Ubiquity TPA portal
</h4>
<p class="fs-h2 text-purple">
  <strong>Where will I go to view reports and complete administrative tasks for Simply Retirement by Principal<sup><small>®</small></sup> plans?</strong>
</p>
<p class="fs-p mb-4">
    TPAs will have access to a TPA portal on the Ubiquity Retirement + Savings<sup><small>®</small></sup> website. The Ubiquity TPA portal includes a dashboard with basic client information. This portal also provides easy access to the information you need to manage day-to-day tasks. For example, on the Ubiquity TPA portal, you'll be able to:
    <ul>
        <li>View plan details such as balance, participation, and outstanding tasks.</li>
        <li>Review plan loans, and distributions, including the ability to view uploaded supporting documents as necessary (e.g., hardships, residential loans, etc.).</li>
        <li>Upload reference documents (e.g. Summary Plan Descriptions, plan compliance test results, Summary Annual Reports).</li>
        <li>Access plan-specific reports.</li>
    </ul>
</p>
`;

const sectionTpaPortal = `
<p class="fs-h2 text-purple">
  <strong>How do I register for and/or access the Ubiquity TPA portal?</strong>
</p>
<p class="fs-p mb-4">
    When an employer decides to purchase their Simply Retirement by Principal<sup>®</sup> plan, the TPA Operational/Administration Contact who was added during the <strong>“Create a Plan”</strong> process will receive an email with a link to register for access to the Ubiquity TPA portal. The email will be sent from Ubiquity Retirement + Savings (<a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a>) and the subject line will be <em>Access your TPA Portal for your Simply Retirement by Principal<sup>®</sup> plan(s)</em>. If you don’t receive the email, check your spam folder first. If it’s not there, call Ubiquity TPA support at 855-700-5569.
</p>
<p class="fs-p mb-4">
    During the initial registration process, the first person to register from your TPA firm will complete these steps:
    <ul>
        <li>Create your login credentials and set up two-factor authentication</li>
        <li>Add information about your firm</li>
        <li>Set your firm payment preference for the firm’s portion of all participant-initiated loans and distribution fees</li>
    </ul>
</p>
<p class="fs-h2 text-purple">
  <strong>How do I add or remove team members on the Ubiquity TPA portal?</strong>
</p>
    To add team members from your firm to the Ubiquity TPA portal:
    <ul>
        <li>Click <strong>Team</strong> from the left navigation</li>
        <li>Click <strong>Create a new user</strong></li>
        <li>Add the team member’s first name, last name, and email address. Each team member will receive an email asking them to complete the registration process to access the Ubiquity TPA portal.</li>
    </ul>
    To remove team members from the Ubiquity TPA portal:
    <ul>
        <li>Click <strong>Team</strong> from the left navigation</li>
        <li>Locate the team member to remove and click <strong>Delete</strong></li>
    </ul>
<p class="mb-4">
    Deleted team members will no longer have the ability to access your Ubiquity TPA portal.
</p>
<p class="fs-h2 text-purple">
  <strong>What can additional team members access on the Ubiquity TPA portal?</strong>
</p>
<p>
Any team members you add ass users will have full access to all features, functionality, and client plan information in your portal, and will receive all portal communications.
</p>
    <strong>Email communications</strong></br>
    All team members with access to your Ubiquity TPA portal will receive the following emails:
    <ul>
        <li>Registration invitation</li>
        <li>Request to upload the Summary Plan Description for a new client</li>
        <li>Review/approve/deny loan requests<sup>*</sup></li>
        <li>Review/approve/deny loan requests<sup>*</sup></li>
        <li>Review/approve/deny distribution requests<sup>*</sup></li>
    </ul>
    <p><sup>*</sup>Once a loan or distribution request has been approved/denied, the request will no longer be accessible from the Ubiquity TPA portal or from the review request emails.</p>

    <strong>User permissions</strong></br>
    All team members will also have access to the following for all plans across your company’s book of business:
    <ul>
        <li>Add or remove documents</li>
        <li>Review/approve/deny loans and distributions</li>
        <li>Run reports </li>
        <li>Add or remove new users</li>
        <li>Update your company’s address</li>
        <li>Add/change how your company receives their portion of the distribution/loan fee</li>
    </ul>

<p class="fs-h2 text-purple">
  <strong>How do I add ACH/banking information for my TPA firm?</strong>
</p>
<p class="fs-p mb-4">
    During the initial Ubiquity TPA portal registration process, the TPA Operational/Administration Contact who receives the first email will set up how your firm will receive their portion of the distribution and loan fees that are collected at the time of processing. The fee can be paid by check or wire.
</p>
<p class="fs-p mb-4">
    To update or change the payment type for the TPA portion of a distribution and loan fee from the TPA portal:
    <ul>
        <li>Click <strong>Payment</strong> from the left navigation</li>
        <li>Select <strong>Check or Wire</strong></li>
        <li>Complete the necessary fields</li>
        <li>Click <strong>Save</strong></li>
    </ul>
</p>

<p class="fs-h2 text-purple">
  <strong>How do I approve loans and distributions?</strong>
</p>
<p class="fs-p mb-4">
    When an employee submits a plan loan or distribution request, an email will be sent to all your firm’s Ubiquity TPA portal members with a link to log in and review and approve the request. Loans and distributions requiring review and approval will also be listed in the alerts section located on your Ubiquity TPA portal dashboard.
</p>
<p class="fs-p mb-4">
    The TPA loan and distribution review page will include the participant’s current balance and vested percentage in each contribution source. Each source’s vested percentage is calculated per the terms of the document and hours submitted by the employer. To maintain accurate vesting in the recordkeeping system, the employer should submit hours per pay period. Any missing hours can be updated on the Ubiquity sponsor website.
</p>
<p class="fs-p mb-4">
    The TPA team member can update the participant’s vested percentage in any source when reviewing the accuracy of the loan or distribution request. <strong><u>Any changes to the employee’s vesting percentages in the loan/distribution review will override the recordkeeping system’s vesting calculation and will require manual intervention by the TPA going forward.</u></strong>
</p>
<p class="fs-p mb-4">
    Here are examples of the loan review screens
</p>
<img
    src=${tpaFaqImage1}
    alt="example loan review screen 1"
    class="d-block mx-auto mt-3 mb-5 img-fluid"
    style="border: 4px solid #002855; box-shadow: 0 0 15px #1d2124"
    fill="#ffffff"
/>
<img
    src=${tpaFaqImage2}
    alt="example loan review screen 2"
    class="d-block mx-auto mt-3 mb-5 img-fluid"
    style="border: 4px solid #002855; box-shadow: 0 0 15px #1d2124"
    fill="#ffffff"
/>
<img
    src=${tpaFaqImage3}
    alt="example loan review screen 3"
    class="d-block mx-auto mt-3 mb-5 img-fluid"
    style="border: 4px solid #002855; box-shadow: 0 0 15px #1d2124"
    fill="#ffffff"
/>
<img
    src=${tpaFaqImage4}
    alt="example loan review screen 4"
    class="d-block mx-auto mt-3 mb-5 img-fluid"
    style="border: 4px solid #002855; box-shadow: 0 0 15px #1d2124"
    fill="#ffffff"
/>
<p class="fs-h2 text-purple">
  <strong>How will the loan or distribution notice be sent?</strong>
</p>
<p class="fs-p mb-4">
    On the loan or distribution review page under the <strong>Delivery</strong> tab, a TPA team member can specify the delivery method and address where the notice will be sent. Please check the delivery information before approving the loan or distribution.
</p>
`;

const sectionAddEmployees = `
<p class="fs-h2 text-purple">
  <strong>Can TPAs add new employees for the plan sponsor?</strong>
</p>
No. Here are instructions you can provide for the plan sponsor to complete this process.
<p class="fs-p mb-4">
    How to add employees:
    <ul>
        <li>Log in to your employer account</li>
        <li>From the left navigation, click on <strong>Employees</strong>, then <strong>Manage Employees</strong></li>
        <li>Add yourself to the census file by clicking the <strong>Add me to Census</strong> button (don't change your email address)</li>
        <li>Add the remaining trustees, if the trustees are employees</li>
        <li>Finally, add ALL active W-2 employees </li>
    </ul>
</p>
<p class="fs-p mb-4">
    You will need to enter the following information for each individual:
    <ul>
        <li>First and last name</li>
        <li>Social Security number</li>
        <li>Date of birth and hire</li>
        <li>Email address (each employee must have their own email address, either work or personal)</li>
        <li>Year-to-date hours worked and W-2 wages</li>
        <li>Prior year hours worked W-2 wages (if hire date is in the previous year)</li>
        <li>Employee address</li>
        <li>Ownership percentage (if applicable)</li>
        <li>Company officer</li>
        <li>Employee relationship (if the employee is related to an owner)</li>
    </ul>
    If you prefer, you can use the <strong>Upload Employee File</strong> feature on the Manage Employees page. The <strong>Census File Spec</strong> will help you format your CSV file.
</p>
<p class="fs-h2 text-purple">
  <strong>How do I set up a custom report?</strong>
</p>
<p>Click <strong>Reports</strong> from the left navigation of your TPA dashboard.</p>
<p>You can select the report, the plan you want to run the report for, and the time frame for the report. The report can be viewed or downloaded as a Comma Separated Value (CSV) file or Microsoft Excel document</p>
<p>Contact <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a> if you need assistance running a report or need additional data not available in the existing reports.</p>

<p class="fs-h2 text-purple">
  <strong>How do I view or add documents to a plan?</strong></br>
  <p>When a client completes the purchase process and signs Ubiquity’s Agreement for Services for the Simply Retirement by Principal® plan, all team members will be sent an email requesting that the Summary Plan Description for the plan be uploaded to the Ubiquity TPA portal. The email will be sent from Ubiquity Retirement + Savings (<a href="mailto:newplan@myubiquity.com">newplan@myubiquity.com</a>) and the subject line will be <em>Upload the SPD for the (Plan Name)</em></p>
</p>
<p>When you click the Upload Documents link in the email and log in, you’ll be navigated to the Documents page for the plan.</p>
<p class="fs-p mb-4">
    Once you are on the plan's Documents page:
    <ul>
        <li>Click the <strong>Add document</strong> button</li>
    </ul>
    <img
    src=${tpaFaqAddDocument1}
    alt="tpa add document image 1"
    class="d-block mx-auto mt-3 mb-5 img-fluid"
    style="border: 4px solid #002855; box-shadow: 0 0 15px #1d2124"
    fill="#ffffff"
    />
    <ul>
        <li>Select Summary Plan Description as the <strong>Document Type</strong></li>
        <li>in the <strong>Upload documents</strong> box, either drag and drop the SPD <strong>or</strong> use the <strong>Browse</strong> link to locate the file on your computer</li>
    </ul></br>
    <img
    src=${tpaFaqAddDocument2}
    alt="tpa add document image 2"
    class="d-block mx-auto mt-3 mb-5 img-fluid"
    style="border: 4px solid #002855; box-shadow: 0 0 15px #1d2124"
    fill="#ffffff"
    />
    You'll be navigated back to the plan's <strong>Document</strong> page.
</p>
<p>Note: The Summary Plan Description document type will not provide the option to indicate who will have access to this document. It will be visible to the trustees, plan sponsors, and participants. In addition, the Summary Plan Description will be distributed to all eligible employees in the plan within their enrollment emails and will be available on their websites.</p>
<p>To add more documents to the Simply Retirement by Principal<sup>®</sup> plan:
    <ul>
        <li>Click the <strong>Add document</strong> button</li>
        <li>Select the <strong>Document Type</strong></li>
        <li>Enter the <strong>Document Name</strong></li>
        <li>Select who should have access to the document (Trustee, Sponsor and/or Participant)</li>
        <li>In the <strong>Upload Document box</strong>, either drag and drop the document <strong>or</strong> use the <strong>Browse</strong> link to find the file on your computer. Note: The Upload Document box will appear once you have selected who will have viewing access.</li>
    </ul>
    </br>
    <img
    src=${tpaFaqAddDocument3}
    alt="example loan review screen 3"
    class="d-block mx-auto mt-3 mb-5 img-fluid"
    style="border: 4px solid #002855; box-shadow: 0 0 15px #1d2124"
    fill="#ffffff"
    />
</p>
<p>Uploading documents via the Ubiquity TPA portal allows your documents to be visible to the audience you selected. Other than the Summary Plan Description, uploaded documents will not be sent out by email.</p>
<p>
    <ul>
        <li>From the left navigation, click <strong>Plans</strong></li>
        <li>Locate the plan</li>
        <li>Select <strong>View Documents</strong> in the <strong>Plan Actions</strong> column</li>
    </ul>
</p>
<p class="fs-h2 text-purple">
  <strong>How do I update my company’s information?</strong>
</p>
<p>You can review or edit your company’s name, EIN or address on the Ubiquity TPA portal by clicking <strong>Company Contact</strong> from the left navigation.</p>
<p class="fs-h2 text-purple">
  <strong>How do I report an error (for example, contributions posted to the wrong source)?</strong>
</p>
<p>If contributions were posted to the wrong source, send an email to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a> and include the following information:
    <ul>
        <li>Plan name</li>
        <li>Payroll period</li>
        <li>Participant name</li>
        <li>A description of the error and how the contribution should have been posted</li>
    </ul>
</p>
<p>Ubiquity's Operations Team will make the correction as necessary within five business days.</p>
<p>If the error was made in the last two business days, please call Ubiquity TPA support at 855-472-0472, and our team can confirm if we can recall the submission before a correction is needed.</p>
<p class="fs-h2 text-purple">
  <strong>How do I handle updates to plan, plan sponsor, and participant data?</strong>
</p>
<p>Simply Retirement by Principal<sup>®</sup> <strong>plan amendments and plan design changes</strong>
<p>Please ensure that plan amendments and plan design changes are eligible under the Simply Retirement by Principal® plan. See this worksheet for eligible options.</p>
    Send an email with the updated signed plan document to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a> and include:
    <ul>
        <li>Plan name</li>
        <li>A description of the plan amendment or design change</li>
        <li>The effective date of the plan amendment or design page</li>
    </ul>
    Ubiquity Retirement + Savings will notify you when the changes have been made to the recordkeeping system. Once notified, upload the updated Summary Plan Description (SPD) to the plan’s Documents page from your Ubiquity TPA portal. The uploaded SPD will be visible to trustees, plan administrators, and employees. The SPD will also be added automatically to enrollment emails.
</p>
<strong>Company name, plan name, and effective date changes</strong></br>
    Send an email with the updated signed plan document to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a> and include:
    <ul>
        <li>Plan name</li>
        <li>A description of what is changed (from and to)</li>
        <li>Effective date of the change</li>
    </ul>
<p>Ubiquity Retirement + Savings will notify you when the changes have been made to the recordkeeping system. </p>
<p>These types of changes generally require amendments to the Group Annuity Contract with Principal. Ubiquity will work with Principal to prepare the amendment and send it to the trustee for signature.</p>
<p>If an updated Summary Plan Description is required, upload the document to the plan’s Documents page from your Ubiquity TPA portal. The uploaded SPD will be visible to trustees, plan administrators, and employees. The SPD will also be added automatically to enrollment emails.</p>
<strong>Participant data reports and updates</strong></br>
    The <u>TPA</u> can <u>view</u> participant data by running the Employee Demographic Report for a specific plan.
    <ul>
        <li>From the left navigation, click on <strong>Reports</strong></li>
        <li>Report Type: Employee Demographic Report</li>
        <li>Plan Name: select the plan</li>
        <li>Date Range: choose the appropriate year or YTD</li>
    </ul>
<p>The <u>employer</u> can <u>add or update</u> an employee’s YTD hours, YTD compensation, ownership percentage and family relationships, and date of termination (add only), or rehire a terminated employee (add only) from the Manage Employees screen of the sponsor website. </p>
<p>Corrections to date of birth, date of hire or date of rehire, or a participant’s name should be sent to <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a> Please include:</p>
<ul>
        <li>Plan name</li>
        <li>Participant’s name</li>
        <li>What the date is and what it should be changed to</li>
        <li>Reason for the change or any additional information or documentation that would help our operations team take the appropriate action.</li>
    </ul>
<p class="fs-h2 text-purple">
  <strong>How do I work with Ubiquity on year-end plan compliance testing or with day-to-day questions?</strong>
</p>
<strong>Year-end plan compliance testing</strong></br>
    Reporting necessary for the TPA to prepare annual plan compliance testing will be available on the Reports page of the Ubiquity TPA portal. Available reports include:
    <ul>
        <li>Employee Demographic report: Census data derived from payroll data captured throughout the year and adjustments to census information (i.e., compensation, hours, ownership) made by the plan sponsor or at the plan sponsor’s request.</li>
        <li>Participant Report by Source and Investment (Trust Accounting Report): Snapshot of the plan balances by participant per source and investment including beginning-of-year balances, contributions, transfers, gain/loss and end-of-year balances.</li>
        <li>Employee Balance: A listing of all active employees regardless of balance and terminated employees with a balance. This report displays each employee’s current total source balance and their vested percentage for that source.</li>
        <li>Contribution Analysis: Summary of hours, compensation, and contributions made during the year.</li>
    </ul>
<p>Reports can be downloaded in CSV and Excel file formats.</p>
<p>To request a corrective distribution, please contact <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com.</a> for the required form.</p>
`;

const sectionSupport = `
<h4 class="fs-h3 mb-3 pt-5">
  Support
</h4>
<strong>How can I get support to set up my plan?</strong>
<p class="fs-p">Every new plan is assigned an Implementation Specialist by Ubiquity to ensure the plan is set up correctly, and to help process the first employee census and payroll contribution.</p>
<p class="fs-p mb-0"><strong>Implementation Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:newplan@myubiquity.com">newplan@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:855-910-4015">855-910-4015</a></p>
<p class="fs-p mb-4">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-h2 text-purple">
  <strong>How do I (and my clients) get support after my plan is set up?</strong>
</p>
<p class="fs-p">Ongoing support of Simply Retirement by Principal<sup><small>®</small></sup> plans is provided by Ubiquity.The following Customer Success Teams are available:</p>
<p class="fs-p mb-0"><strong>Employer Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569">855-700-5569</a></p>
<p class="fs-p">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-p mb-0"><strong>Employee Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:info@myubiquity.com">info@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18554014357">855-401-4357, Option 1</a></p>
<p class="fs-p">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-p mb-0"><strong>TPA Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569">855-700-5569</a></p>
<p class="fs-p mb-3">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-p mb-0"><strong>Financial Professional Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569">855-700-5569</a></p>
<p class="fs-p mb-4">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
`;

const TpaFaq = () => (
  <div className="about-page mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
    />

    <SectionSingleCol
      copy={sectionGeneralInformation}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-6 pb-2"
      id="general_information"
    />

    <SectionSingleCol
      copy={sectionCompensationAndFees}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-6 pb-2"
      id="compensation_and_fees"
    />

    <SectionSingleCol
      copy={sectionPlanDesign}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-6 pb-2"
      id="plan_design"
    />

    <SectionSingleCol
      copy={sectionPlanFeatures}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-6 pb-2"
      id="plan_features"
    />

    <SectionSingleCol
      copy={sectionOnBoarding}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-6 pb-2"
      id="onboarding"
    />

    <SectionSingleCol
      copy={sectionPlanAdministration}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-6 pb-2"
      id="tpa_portal"
    />

    <SectionSingleCol
      copy={sectionTpaPortal}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-5 mt-6"
      id="tpa_portal_and_registration"
    />

    <NoticesTableSection />

    <SectionSingleCol
      copy={sectionAddEmployees}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-5 mt-6"
      id="add_employees"
    />

    {/* <SupportTableSection /> */}

    <SectionSingleCol
      copy={sectionSupport}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-5 mt-6"
      id="support"
    />
  </div>
);

export default TpaFaq;
