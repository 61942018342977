import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import HeaderSection from '../../../../common/HeaderSection';
import { PrincipalTabs, Item } from '../../../../common/PrincipalTabs';
import TabContent from './TabContent';
import { ResourcesHero, CheckCircle } from '../../../../ImagePaths';

const sectionHeader = {
  image: ResourcesHero,
  title: 'Financial professional retirement resources',
  caption: `
    <p class="fs-display5">
      Managing and servicing client needs is a full-time job. Save
      time with these resources to help you educate small business
      clients and make the most of your opportunities in this market.
    </p>
  `,
  alt: 'Employee in a meeting.',
  copy: ``,
};

const tabsRunning = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Growing your small plan retirement business
    </h3>
    <p class="fs-h2 mb-5">
      Get tips to reach prospects effectively and sell your value as a financial professional.
    </p>
  `,
  resourceCards: [
    {
      id: 0,
      headline:
        '4 ways to be more efficient in the retirement small plan market',
      date: 'October, 2021',
      link: 'https://advisors.principal.com/wps/portal/advisor/products/retirement-plans/small-business-retirement-solutions/efficient-in-retirement-small-plan-market',
    },
    {
      id: 1,
      headline:
        '6 tips for closing a 401(k) sale in the small retirement business market',
      date: 'October, 2021',
      link: 'https://advisors.principal.com/wps/portal/advisor/products/retirement-plans/small-business-retirement-solutions/tips-for-closing-401k-sale-in-small-market',
    },
    {
      id: 2,
      headline:
        'Helping you sell: Financial Professionals share how they target SMBs for retirement plans',
      date: 'October, 2021',
      link: 'https://advisors.principal.com/wps/portal/advisor/products/retirement-plans/small-business-retirement-solutions/how-to-target-smb-retirement-plans',
    },
    {
      id: 3,
      headline: 'Client service calendar',
      date: 'March, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ7880&ty=DOCX&EXT=.DOCX',
      linkText: 'Download Document',
    },
  ],
};

const tabsSales = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Sales and marketing materials to help you do more
    </h3>
    <p class="fs-h2 mb-5">
      Get tips to reach prospects effectively and sell your value as a financial professional.
    </p>
  `,
  resourceCards: [
    {
      id: 11,
      headline:
        'Simply Retirement by Principal - Financial Professional Brochure (TPA)',
      date: 'November, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736&ty=VOP',
      linkText: 'Download Document',
    },
    {
      id: 10,
      headline:
        'Simply Retirement by Principal – Financial Professional Brochure (Bundled)',
      date: 'November, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736V&ty=VOP',
      linkText: 'Download Document',
    },
    {
      id: 9,
      headline: '5 tips to help grow your practice',
      date: 'December, 2020',
      link: '/financial-professionals/resources/5-tips-to-help-grow-your-practice',
    },
    {
      id: 0,
      headline: '6 key attributes of your target prospects',
      date: 'April, 2020',
      link: 'https://www.principal.com/landing-page/small-employer-plans-webinar-recording?WT.mc_id=h297-RIS-201808-10-small-plan-webinar-series-recording-hub',
      linkText: 'Watch Webinar',
    },
    {
      id: 1,
      headline: 'Small business prospecting: Social media sample posts',
      date: 'April, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12337C&ty=DOCX&EXT=.DOCX',
      linkText: 'Download Document',
    },
    {
      id: 2,
      headline: 'Small business prospecting: Email sample messages',
      date: 'April, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12337B&ty=DOCX&EXT=.DOCX',
      linkText: 'Download Document',
    },
    {
      id: 3,
      headline: 'Why target the small business retirement plan market?',
      date: 'March, 2020',
      link: 'https://advisors.principal.com/wps/portal/advisor/products/retirement-plans/small-business-retirement-solutions/target-small-business-retirement-plan',
    },
    {
      id: 4,
      headline: '10 tips to help grow your small plan business',
      date: 'March, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12337S&ty=VOP&EXT=.VOP',
    },
    {
      id: 6,
      headline: 'Four simple steps to create a value proposition (PDF)',
      date: 'March, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12067A&ty=VOP&EXT=.VOP',
    },
    {
      id: 7,
      headline: 'Value proposition: Financial professional examples',
      date: 'March, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12067I&ty=VOP&EXT=.VOP',
    },
    {
      id: 8,
      headline: 'Marketing your value prop',
      date: 'March, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12067J&ty=VOP&EXT=.VOP',
    },
  ],
};

const tabsAbout = {
  intro: `
    <h3 class="fs-h3 text-purple">
      About retirement plans
    </h3>
    <p class="fs-h2 mb-5">
      Looking for a big-picture view? Check out this section for general information about retirement plans.
    </p>
  `,
  resourceCards: [
    {
      id: 2,
      headline: 'Small business startup plan tax advantages',
      date: 'December, 2020',
      link: '/financial-professionals/resources/small-business-startup-plan-tax-advantages',
    },
    {
      id: 1,
      headline: 'New digital 401(k) solution',
      date: 'December, 2020',
      link: '/financial-professionals/resources/new-digital-401k-solution',
    },
    {
      id: 0,
      headline: 'Retirement plans at a glance',
      date: 'April, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=MM1744C&ty=VOP&EXT=.VOP',
    },
  ],
};

const tabsResources = {
  intro: `
    <h3 class="fs-h3 text-purple">
      Resources for your clients
    </h3>
    <p class="fs-h2 mb-5">
      Educate your small business clients about retirement plans and their role as plan sponsor.
    </p>
  `,
  resourceCards: [
    {
      id: 0,
      headline:
        'Why offer a competitive retirement plan? A resource for plan sponsors',
      date: 'April, 2020',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12337T&ty=VOP&EXT=.VOP',
    },
    {
      id: 1,
      headline: 'Your small business is the right size for a 401(k)',
      date: 'March, 2020',
      link: 'https://www.myubiquity.com/401k-plan-information/small-business-big-benefits/',
    },
    {
      id: 2,
      headline: 'What best-in-class retirement plan sponsors do differently',
      date: 'March, 2020',
      link: 'https://www.principal.com/employers/trends-insights/what-best-class-retirement-plan-sponsors-do-differently',
    },
  ],
};

const Resources = () => (
  <div className="resources-page mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
    />
    <section
      className="section position-relative"
      style={{ marginTop: '-70px', zIndex: '1' }}
    >
      <div className="container-xl px-0">
        <PrincipalTabs>
          <Item
            title="Growing your small plan retirement business"
            component={
              <TabContent
                intro={tabsRunning.intro}
                resourceCards={tabsRunning.resourceCards}
              />
            }
          />
          <Item
            title="Sales and marketing materials to help you do more"
            component={
              <TabContent
                intro={tabsSales.intro}
                resourceCards={tabsSales.resourceCards}
              />
            }
          />
          <Item
            title="About retirement plans"
            component={
              <TabContent
                intro={tabsAbout.intro}
                resourceCards={tabsAbout.resourceCards}
              />
            }
          />
          <Item
            title="Resources for your clients"
            component={
              <TabContent
                intro={tabsResources.intro}
                resourceCards={tabsResources.resourceCards}
              />
            }
          />
        </PrincipalTabs>
      </div>
    </section>
    <section className="section section_py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <h3 className="fs-h3 text-center mb-5">
              See what Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              has to offer.
            </h3>
          </div>
        </div>
        <div className="row d-print-none">
          <div className="col-lg-7 mx-auto">
            <p className="fs-h6 text-center mb-5">
              Watch this video demo and see how we make it easy to design and
              manage retirement plans for small businesses.
            </p>
            <ReactPlayerLoader
              accountId="1510329453001"
              videoId="6274775432001"
              attrs={{
                className: 'brightcove-player',
              }}
              embedOptions={{ responsive: { aspectRatio: '16:9' } }}
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section section_py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <img
              src={CheckCircle}
              alt="Checkmark In Circle Icon"
              className="d-block mx-auto mb-3"
            />
            <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
            <p className="text-center pb-5 mb-5">
              <Link to="/plans" className="srp-button srp-button__primary">
                Create a proposal
              </Link>
            </p>
            <p className="fs-disclaimer text-center pb-5 mb-5">
              Intended for financial professional and third party administrator
              use.
            </p>
            <span className="d-none">1178600-052020</span>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Resources;
