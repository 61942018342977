import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../Redux/FormDataRedux';
import { asOfDate } from '../../../../Services/utilities';

const TableFluidLarge = ({
  tableHeaders,
  tableRows,
  applicationPanelInfo,
  putApplicationPanelInfo,
}) => {
  const [selectedRow, setSelectedRow] = useState(
    tableRows[0].header.replace(/[^\w]/gi, ''),
  );
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  return (
    <div className="table-fluid-large mb-5">
      <div className="table-fluid-large__thead">
        <div className="table-fluid-large__label highlight justify-content-center">
          <strong>Asset Class</strong>
        </div>
        {tableHeaders.map((tableHeader) => (
          <div
            key={tableHeader.id}
            className={classNames('table-fluid-large__col', {
              highlight: tableHeader.highlight,
            })}
          >
            <div className="heading">
              <div dangerouslySetInnerHTML={{ __html: tableHeader.desc }} />
              <button
                type="button"
                className="btn btn-link d-block d-print-none mx-auto fs-disclaimer p-0"
                onClick={() => {
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    additionalInformation: `<h2 class="pb-3 pb-lg-4 fs-h2 lh-125 text-white fw-bold">${tableHeader.desc}</h2> ${tableHeader.moreInfo}`,
                  });
                }}
              >
                {tableHeader.btn}
              </button>
            </div>
          </div>
        ))}
      </div>
      {tableRows.map((tableRow, indx) => (
        <div
          id={tableRow.header.replace(/[^\w]/gi, '')}
          key={tableRow.id}
          className={classNames('table-fluid-large__row', {
            active: selectedRow === tableRow.header.replace(/[^\w]/gi, ''),
          })}
        >
          <div
            className={classNames('table-fluid-large__label highlight', {
              'mleft-0': !tableRows[indx - 1],
            })}
          >
            {tableRows[indx - 1] && (
              <button
                type="button"
                className="table-fluid-large__next-btn"
                onClick={() => {
                  setSelectedRow(
                    tableRows[indx - 1].header.replace(/[^\w]/gi, ''),
                  );
                }}
              >
                <i className="fa-solid fa-angle-left" aria-hidden />
                <span className="sr-only">More info</span>
              </button>
            )}
            <div className="label">
              <strong dangerouslySetInnerHTML={{ __html: tableRow.header }} />
              {tableRow.moreInfo && (
                <>
                  <button
                    type="button"
                    className="btn btn-link d-block d-print-none ml-md-0 mx-auto fs-disclaimer p-0"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        additionalInformation: `<h2 class="text-white fw-bold fs-h3">${tableRow.header}</h2> ${tableRow.moreInfo}`,
                      });
                    }}
                  >
                    More info
                  </button>
                  <div
                    className="d-print-block d-none"
                    dangerouslySetInnerHTML={{
                      __html: tableRow.moreInfo,
                    }}
                  />
                </>
              )}
            </div>
            {tableRows[indx + 1] && (
              <button
                type="button"
                className="table-fluid-large__next-btn"
                onClick={() => {
                  setSelectedRow(
                    tableRows[indx + 1].header.replace(/[^\w]/gi, ''),
                  );
                }}
              >
                <i className="fa-solid fa-angle-right" aria-hidden />
                <span className="sr-only">More info</span>
              </button>
            )}
          </div>
          {tableRow.rowDesc.map((rowDesc, idx) => (
            <div
              key={`${tableRow.id}${rowDesc.id}`}
              className={classNames('table-fluid-large__col', {
                highlight: tableHeaders[idx].highlight,
              })}
            >
              <div className="table-col scope">
                <div
                  dangerouslySetInnerHTML={{ __html: tableHeaders[idx].desc }}
                />
                {tableHeaders[idx].moreInfo && (
                  <button
                    type="button"
                    className="btn btn-link d-block fs-disclaimer p-0 d-print-none"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        additionalInformation: `<h2 class="pb-3 pb-lg-4 fs-h2 lh-125 text-white fw-bold">${tableHeaders[idx].desc}</h2> ${tableHeaders[idx].moreInfo}`,
                      });
                    }}
                  >
                    {tableHeaders[idx].btn}
                  </button>
                )}
              </div>
              <span
                className="table-col value text-left"
                dangerouslySetInnerHTML={{ __html: rowDesc.desc }}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

TableFluidLarge.defaultProps = {
  tableHeaders: [
    {
      id: '01',
      col: 1,
      desc: `
        <strong class="d-block">Wilshire 3(38)</strong>
        <strong class="d-block">Investment Lineup 1</strong>
      `,
      btn: '(0.05% - 0.70%)',
      moreInfo: `
        <div>
          Investment fees vary and range from 0.05% – 0.70% (as of ${asOfDate}), depending on the investment chosen.
        </div>
        <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PG5669-0&ty=VOP" target="_blank">
          Review investment option performance results and statistics.
        </a>
      `,
    },
    {
      id: '02',
      col: 2,
      desc: `
        <strong class="d-block">Wilshire 3(38)</strong>
        <strong class="d-block">Investment Lineup 2</strong>
      `,
      btn: ' (0.03% - 0.89%)',
      moreInfo: `
        <div>
          Investment fees vary and range from 0.03% – 0.89% (as of ${asOfDate}), depending on the investment chosen.
        </div>
        <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PG5670-0&ty=VOP" target="_blank">
          Review investment option performance results and statistics.
        </a>
      `,
    },
    {
      id: '03',
      col: 3,
      desc: `
        <strong class="d-block">Wilshire 3(38)</strong>
        <strong class="d-block">Investment Lineup 3</strong>
      `,
      btn: '(0.05% - 0.74%)',
      moreInfo: `
        <div>
          Investment fees vary and range from 0.05% – 0.74% (as of ${asOfDate}), depending on the investment chosen.
        </div>
        <a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PG5671-0&ty=VOP" target="_blank">
          Review investment option performance results and statistics.
        </a>
      `,
    },
  ],

  tableRows: [
    {
      id: 'table1',
      header: 'US Equity Large-Cap',
      rowDesc: [
        {
          id: 1,
          desc: `
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=4395&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">LargeCap Growth I Separate Account CL Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=80&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">LargeCap S&P 500 Index Separate Account CL Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=4397&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">LargeCap Value III Separate Account CL Z</a>
        `,
        },
        {
          id: 2,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/wfspx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">iShares S&P 500 Index K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/bfgbx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock Capital Appreciation K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/mkdvx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock Equity Dividend K Fund</a>
        `,
        },
        {
          id: 3,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/mfekx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">MFS Growth R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/meikx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">MFS Value R6 Fund</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=80&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">LargeCap S&P 500 Index Separate Account CL Z</a>
        `,
        },
      ],
    },
    {
      id: 'table2',
      header: `US Equity Small/Mid-Cap`,
      rowDesc: [
        {
          id: 1,
          desc: `
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=4393&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">SmallCap Growth I Separate Account CL Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=191&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">SmallCap S&P 600 Index Separate Account Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=6211&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">SmallCap Value II Separate Account CL Z</a>
        `,
        },
        {
          id: 2,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/bmgkx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock Mid-Cap Growth Equity K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/mjrfx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock Mid Cap Value K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/bdbkx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">iShares Russell 2000 Small-Cap Index K Fund</a>
        `,
        },
        {
          id: 3,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/otckx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">MFS Mid-Cap Growth R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/mvckx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">MFS Mid Cap Value R6 Fund</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=191&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">SmallCap S&P 600 Index Separate Account Z</a>
          `,
        },
      ],
    },
    {
      id: 'table3',
      header: `International Equity`,
      rowDesc: [
        {
          id: 1,
          desc: `
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=10020&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">International Equity Index Separate Account CL Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=156&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">International I Separate Account CL Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9320&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Overseas Separate Account CL Z</a>
        `,
        },
        {
          id: 2,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/btmkx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">iShares MSCI EAFE International Index K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/hncux/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Hartford International Growth R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/sidrx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Hartford Schroders International Multi-Cap Value SDR Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/mkdcx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock Emerging Markets K Fund</a>
        `,
        },
        {
          id: 3,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/rergx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">American Funds Euro Pacific Growth R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/mdizx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">MFS International Diversification R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/rllgx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">American Funds SMALLCAP World R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/rnwgx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">American Funds New World R6 Fund</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=10020&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">International Equity Index Separate Account-Z</a>
        `,
        },
      ],
    },
    {
      id: 'table4',
      header: `Fixed Income`,
      rowDesc: [
        {
          id: 1,
          desc: `
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=10021&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Bond Market Index Separate Account Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=60&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Core Plus Bond Separate Account CL Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13647&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">High Yield Separate Account CL Z</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=6447&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Inflation Protection Separate Account CL Z</a>
        `,
        },
        {
          id: 2,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/wfbix/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">iShares US Aggregate Bond Index K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/mphqx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock Total Return K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/brhyx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock High Yield Bond K Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/bplbx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">BlackRock Inflation Protected Bond K Fund</a>
        `,
        },
        {
          id: 3,
          desc: `
            <a href="https://www.morningstar.com/funds/xnas/rilfx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">American Funds Inflation Linked Bond R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/rbfgx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">American Funds Bond Fund of America R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/ltrhx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Lord Abbett Total Return R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/lhyvx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Lord Abbett High Yield R6 Fund</a>
            <a href="https://www.morningstar.com/funds/xnas/rcwgx/quote" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">American Funds Capital World Bond R6 Fund</a>
        `,
        },
      ],
    },
    {
      id: 'table5',
      header: `Target Date`,
      rowDesc: [
        {
          id: 1,
          desc: `
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9260&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2015</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9261&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2020</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9262&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2025</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9263&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2030</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9264&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2035</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9265&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2040</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9266&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2045</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9267&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2050</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9268&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2055</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13755&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2060</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=16896&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2065</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&symbol=T7097" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2070</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9269&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid Income CIT</a>
        `,
        },
        {
          id: 2,
          desc: `
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9260&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2015</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9261&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2020</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9262&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2025</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9263&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2030</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9264&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2035</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9265&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2040</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9266&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2045</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9267&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2050</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9268&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2055</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13755&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2060</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=16896&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2065</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&symbol=T7097" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2070</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9269&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid Income CIT</a>
        `,
        },
        {
          id: 3,
          desc: `
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9260&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2015</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9261&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2020</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9262&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2025</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9263&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2030</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9264&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2035</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9265&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2040</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9266&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2045</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9267&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2050</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9268&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2055</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13755&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2060</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=16896&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2065</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&symbol=T7097" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid CIT 2070</a>
            <a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9269&rtclss=97" target="_blank"  class="d-block mb-2 lh-100 mx-md-4">Principal Lifetime Hybrid Income CIT</a>
        `,
        },
      ],
    },
    {
      id: 'table6',
      header: `Capital Preservation`,
      rowDesc: [
        {
          id: 1,
          desc: `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Morley Stable Value Fund</a>`,
        },
        {
          id: 2,
          desc: `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Morley Stable Value Fund</a>`,
        },
        {
          id: 3,
          desc: `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Morley Stable Value Fund</a>`,
        },
      ],
    },
    {
      id: 'table7',
      header: `Real Estate`,
      rowDesc: [
        {
          id: 1,
          desc: `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13646&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Global Real Estate Securities Separate Account CL Z</a>`,
        },
        {
          id: 2,
          desc: `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13646&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Global Real Estate Securities Separate Account CL Z</a>`,
        },
        {
          id: 3,
          desc: `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13646&rtclss=97" target="_blank" class="d-block mb-2 lh-100 mx-md-4">Global Real Estate Securities Separate Account CL Z</a>`,
        },
      ],
    },
  ],
};

TableFluidLarge.propTypes = {
  tableHeaders: PropTypes.array,
  tableRows: PropTypes.array,
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableFluidLarge);
