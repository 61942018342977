import React from 'react';
import TableFluidNotices from './TableFluidNotices';

const NoticesTableSection = () => (
  <section id="notices-table" className="table-section section_py-5 pt-0">
    <div className="container-lg bg-white">
      <div className="row">
        <div className="col-lg-10 mx-auto px-md-3 px-0">
          <p className="fs-h2 text-purple">
            <strong>
              Which notices does Ubiquity send directly to participants?
            </strong>
          </p>
          <p className="fs-p mb-4">
            The following outlines the participant notices provided by Ubiquity
            Retirement + Savings:
          </p>
          <TableFluidNotices />
          <p>
            All Ubiquity-generated notices are sent electronically and are
            available to the sponsor and the participant on Ubiquity’s plan
            sponsor and participant websites.{' '}
          </p>
        </div>
      </div>
    </div>
  </section>
);

NoticesTableSection.propTypes = {};

export default NoticesTableSection;
