import moment from 'moment';
import { effectiveMinMaxDate } from '../../Services/planEffectiveDate';

export const verifyPlanEffectiveDate = ({
  isSafeHarbor,
  planDate,
  hasTpa,
  callback,
}) => {
  if (moment(planDate).diff(moment(), 'days') < 21 && !hasTpa) {
    callback({
      name: 'planEffectiveDate',
      value: effectiveMinMaxDate(isSafeHarbor).min,
      hasError: false,
      dirty: true,
      valueFormatted: effectiveMinMaxDate(isSafeHarbor).min,
    });
  }
};
