import { asOfDate } from '../../../Services/utilities';
const date = new Date();

export const TRANSFER_PLAN_VERIFICATION_CODE = '90210';
export const PLAN_DESIGN_INITIAL_STATE = {
  hasGrandfatherDate: {
    id: 'hasGrandfatherDate',
    type: 'radio_button',
    name: 'hasGrandfatherDate',
    stepName: 'step_4',
    paradigmAlias: 'hasGrandfatherDate',
    user: {
      employer: {
        label:
          "Should existing employees be allowed to bypass the plan's eligibility requirements (Grandfather Date)?",
        infoLabel: '',
      },
      advisor: {
        label:
          "Should existing employees be allowed to bypass the plan's eligibility requirements (Grandfather Date)?",
        infoLabel: '',
      },
      tpa: {
        label:
          "Should existing employees be allowed to bypass the plan's eligibility requirements (Grandfather Date)?",
        infoLabel: '',
      },
    },
    heading: '',
    content: '',
    options: [
      {
        id: 1,
        user: {},
        label: 'No',
        info: 'All employees must meet the service requirement',
        value: 'no',
        valueFormatted: 'No',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Yes',
        info: "Allow employees to bypass the plan's eligibility requirements",
        value: 'yes',
        valueFormatted: 'Yes',
        checked: false,
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Grandfather date eligibility',
    reviewScreenTitle: 'Grandfather date eligibility',
    optionDescription: '',
    description: '',
  },
  grandfatherDateHasCustomDate: {
    id: 'grandfatherDateHasCustomDate',
    type: 'radio_condensed',
    name: 'grandfatherDateHasCustomDate',
    stepName: 'step_5',
    paradigmAlias: 'grandfatherDateHasCustomDate',
    user: {
      employer: {
        label: 'As of what date should the eligibility requirements be waived?',
        infoLabel: '',
      },
      advisor: {
        label: 'As of what date should the eligibility requirements be waived?',
        infoLabel: '',
      },
      tpa: {
        label: 'As of what date should the eligibility requirements be waived?',
        infoLabel: '',
      },
    },
    heading: '',
    content: '',
    options: [
      {
        id: 1,
        user: {},
        label: 'Plan Effective Date',
        value: 'no',
        valueFormatted: 'No',
        checked: false,
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Custom Date',
        value: 'yes',
        valueFormatted: 'Yes',
        checked: false,
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Grandfather Date Selection:',
    reviewScreenTitle: 'Grandfather Date Selection:',
    optionDescription: '',
    description: '',
  },
  grandfatherDate: {
    id: 'grandfatherDate',
    stepName: 'step_5',
    type: 'date',
    name: 'grandfatherDate',
    paradigmAlias: 'grandfatherDate',
    user: {
      employer: {
        label: '',
        infoLabel: '',
      },
      advisor: {
        label: '',
        infoLabel: '',
      },
      tpa: {
        label: '',
        infoLabel: '',
      },
    },
    inputLabel: '',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    reviewScreenTitle: 'Grandfather date eligibility',
    sideCardTitle: 'Grandfather date eligibility',
    description:
      "Existing employees can bypass the plan's eligibility requirements if a grandfather date is elected.",
    info: "Existing employees can bypass the plan's eligibility requirements if a grandfather date is elected.",
    infoDate: '',
  },
  isCurrentPrincipalPlan: {
    id: 'isCurrentPrincipalPlan',
    type: 'radio_condensed',
    name: 'isCurrentPrincipalPlan',
    paradigmAlias: 'isCurrentPrincipalPlan',
    stepName: 'isCurrentPrincipalPlan',
    labelClass: 'fs-form-q fw-bold mb-3 text-center',
    user: {
      employer: {
        label: `Do you currently have a retirement plan with Principal Financial Group<sup><small>®</small></sup>?`,
      },
      advisor: {
        label: `Does the client currently have a retirement plan with Principal Financial Group<sup><small>®</small></sup>?`,
      },
      tpa: {
        label: `Does the client currently have a retirement plan with Principal Financial Group<sup><small>®</small></sup>?`,
      },
    },
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'yes',
        valueFormatted: 'Yes',
        checked: false,
        icon: 'icon-check-o',
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'no',
        valueFormatted: 'No',
        checked: false,
        icon: 'icon-close-o',
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    verificationCodeValue: '',
    hasError: false,
    sideCardTitle: 'Is PFG Plan?',
    reviewScreenTitle: 'Conversion Plan',
    optionDescription: '',
  },
  transferPlanInputVerificationCode: {
    id: 'transferPlanInputVerificationCode',
    type: 'text_field',
    name: 'transferPlanInputVerificationCode',
    paradigmAlias: 'transferPlanInputVerificationCode',
    user: {},
    stepName: 'isCurrentPrincipalPlan',
    labelClass: 'fs-form-label fs-form-label--large fw-normal mb-3 text-center',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
    sideCardTitle: 'Verification Code',
    optionDescription: '',
  },
  numberOfEmployees: {
    id: 7,
    stepName: 'step_1',
    type: 'number_field',
    name: 'numberOfEmployees',
    hasInfo: true,
    user: {
      employer: {
        label:
          'How many full-time and part-time employees do you employ (including yourself)?',
      },
      advisor: {
        label:
          'How many employees (including the business owner) would be eligible for the plan?',
      },
      tpa: {
        label:
          'How many employees (including the business owner) would be eligible for the plan?',
      },
    },
    heading: 'Include all full-time and part-time employees.',
    content:
      'Union employees, independent contractors, and nonresident aliens aren’t eligible for Simply Retirement by Principal<small><sup>®</sup></small>.',
    placeholder: 'Number of employees',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: true,
    validateRule: 'LARGER_EMPLOYEE_RULE',
    hasError: false,
    sideCardTitle: 'Total number of employees',
    reviewScreenTitle: 'Total number of employees',
    description:
      'This is the number of full-time and part-time employees age 21 or older (including the business owner) in the business.',
  },
  averageSalary: {
    id: 8,
    stepName: 'step_2',
    type: 'slider',
    name: 'averageSalary',
    valuePrefix: '$',
    labelClass: 'fs-form-q fw-bold text-center py-3',
    user: {
      employer: {
        label: `What's the estimated average annual pay for an employee at your business?`,
        infoLabel:
          'Don’t worry; a ballpark number is fine. This will only be used to estimate costs if you choose to make matching contributions. If you aren’t sure what your employees’ average salary is, just use the pre-filled amount.*',
      },
      advisor: {
        label: 'What is the estimated average annual pay for an employee?',
        infoLabel:
          'Don’t worry; a ballpark number is fine. This will only be used to estimate costs if you choose to make matching contributions. If you aren’t sure what your employees’ average salary is, just use the pre-filled amount.*',
      },
      tpa: {
        label:
          'What’s the estimated average annual pay for an employee at your client’s business?',
        infoLabel:
          'Don’t worry; a ballpark number is fine. This will only be used to estimate costs if your client chooses to make matching contributions. If you aren’t sure what the average employee’s salary is, use the pre-filled amount.*',
      },
    },
    placeholder: 'Average salary/year',
    hasInfo: false,
    format: 'currency',
    required: true,
    dirty: false,
    disabled: false,
    min: 20000,
    max: 150000,
    step: 5000,
    value: 50000,
    valueFormatted: '$50,000',
    defaultValue: 50000,
    hasConditional: false,
    thousandSeperator: true,
    hasError: false,
    sideCardTitle: 'Average annual pay per employee',
    reviewScreenTitle: 'Average annual pay per employee',
    optionDescription: '',
    description:
      'This will only be used to estimate costs if the business owner chooses to make matching contributions.',
    disclaimer:
      '*According to the Bureau of Labor statistics, the median weekly earnings of full-time workers in the U.S. were $936 in the fourth quarter of 2019 (multiplied by 52 weeks for a total of $48,672 per year). Source: https://www.bls.gov/news.release/pdf/wkyeng.pdf',
  },
  serviceEligibility: {
    id: 9,
    stepName: 'step_3',
    type: 'radio_button',
    name: 'serviceEligibility',
    user: {
      employer: {
        label: 'When will employees be enrolled in the retirement plan?',
      },
      advisor: {
        label: 'When will employees be enrolled in the retirement plan?',
      },
    },
    infoLabel:
      'Full-time and part-time employees who are at least 21 years old will be eligible for contributions.',
    options: [
      {
        id: 1,
        user: {},
        label: 'First day of work',
        value: 'IMMEDIATE',
        valueFormatted: 'First day',
        checked: false,
        icon: 'icon-first-day-o',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'After 3 months of work',
        value: 'THREE_MONTHS',
        valueFormatted: '3 months',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
      {
        id: 3,
        user: {},
        label: 'First anniversary',
        value: 'TWELVE_MONTHS',
        valueFormatted: 'First anniversary',
        checked: false,
        icon: 'icon-anniversary-o',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Eligible employees enrolled',
    reviewScreenTitle: 'Employee Service Eligibility Requirement',
    optionDescription: `
      <p class="mb-0 font-italic"><strong><em>You selected from these options:</em></strong></p>
      <ul class="font-italic"><li><em>First day of work</em></li> <li><em>After 3 months of work</em></li> <li><em>First anniversary</em></li></ul>
    `,
    description:
      'This is how long an eligible employee (age 21 or older) must be employed at the organization before enrollment in the plan. They can opt out anytime.',
  },
  employerPaidParticipantFees: {
    id: 10,
    stepName: 'step_9',
    type: 'radio_condensed',
    name: 'employerPaidParticipantFees',
    user: {
      employer: {
        label: 'Who will pay the monthly $6 per  participant fee?',
        infoLabel:
          'There is a flat monthly $6 recordkeeping fee per participating employee for the Simply Retirement by Principal<small><sup>®</sup></small> 401(k) plan. You, as the business owner, can pay this fee quarterly—or it can be deducted from each participating employee’s plan assets monthly.',
      },
      advisor: {
        label: 'Who will pay the monthly $6 per  participant fee?',
        infoLabel:
          'There is a flat monthly $6 recordkeeping fee per participating employee with over $100 in the plan. The business owner can pay this fee quarterly—or it can be deducted from each participant’s plan assets monthly.',
      },
      tpa: {
        label: 'Who will pay the monthly $6 per participant fee?',
        infoLabel:
          'There is a flat monthly $6 recordkeeping fee per participating employee with over $100 in the plan. The business owner can pay this fee quarterly—or it can be deducted from each participant’s plan assets monthly.',
      },
    },
    heading: 'How could the SECURE 2.0 Act help with this fee?',
    content:
      'The SECURE 2.0 Act provides a tax credit for up to 50% of the qualified new plan startup costs paid or incurred by a small business for 3 years.* If you choose to cover the monthly $6 per participant fee as an added benefit to your employees, this will increase the total plan startup costs that may qualify for this tax credit. Consult with a tax advisor or other appropriate counsel for details. <p class="fs-disclaimer">*(1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000.</p>',
    options: [
      {
        id: 1,
        user: {
          employer: {
            label: 'Business owner (you)',
          },
          advisor: {
            label: 'Business owner (your client)',
          },
        },
        label: 'Business owner',
        value: 'true',
        valueFormatted: 'Business owner',
        checked: false,
        icon: 'icon-single-user-o',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: 'Employees',
        value: 'false',
        valueFormatted: 'Employees',
        checked: false,
        icon: 'icon-multi-user-o',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: false,
    hasError: false,
    reviewScreenTitle: 'Who pays the monthly $6 per participant fee',
    sideCardTitle: 'Responsible for recordkeeping fee',
    description:
      'There is a flat monthly $6 fee per participating employee for the Simply Retirement 401(k) plan. The business owner can pay this fee quarterly—or it can be deducted from each participating employee’s plan assets monthly.',
  },
  minimumAutoDeferralPercentage: {
    id: 11,
    stepName: 'step_7',
    type: 'slider',
    name: 'minimumAutoDeferralPercentage',
    labelClass: 'fs-form-q fw-bold text-center py-3',
    user: {
      employer: {
        label:
          'What percentage of an employee’s pay (pre-tax) would you like to set as the default contribution?',
        infoLabel:
          'You’ve chosen automatic enrollment. That means you need to select a default percentage that all eligible employees will start contributing when they are automatically enrolled in the plan. Don’t worry; employees can change this amount or opt out at any time.',
      },
      advisor: {
        label:
          'What percentage of an employee’s pay (pre-tax) should be the default contribution?',
        infoLabel:
          'You’ve chosen automatic enrollment. That means you need to select a default percentage that all eligible employees will start contributing when they are automatically enrolled in the plan. Don’t worry; employees can change this amount or opt out at any time.',
      },
    },
    heading: 'What’s automatic enrollment?',
    content:
      'Automatic enrollment is a plan provision which automatically enrolls participants into the retirement plan at a specified salary deferral percentage unless the participant elects otherwise by completing an enrollment form. This  can help increase participation, simplify administration, and help employees save for retirement. As an added benefit, the SECURE 2.0 Act offers a new tax credit for small businesses implementing automatic enrollment using an eligible automatic contribution arrangement (EACA). It’s $500 annually for 3 years. This is in addition to the plan start-up credit. Consult with a tax advisor or other appropriate counsel for details.',
    format: 'percentage',
    required: true,
    dirty: false,
    min: 3,
    max: 8,
    defaultValue: 5,
    disabled: false,
    thousandSeperator: false,
    valueFormatted: '5%',
    step: 1,
    value: 5,
    hasConditional: false,
    hasError: false,
    reviewScreenTitle: 'Automatic enrollment employee contribution',
    sideCardTitle: 'Default employee contribution',
    optionDescription: `
      <p class="font-italic mb-0"><strong>You selected from these options:</strong></p>
      <ul class="font-italic"><li>3%, 4%, 5%, 6%, 7%, 8%</li></ul>
    `,
    description:
      'This plan has automatic enrollment, which means you must select a default pre-tax contribution percentage that eligible employees will start contributing when they are automatically enrolled in the plan. Their contributions will be directed to the plan’s QDIA (Qualified Default Investment Alternative) unless they elect otherwise. A QDIA is a default investment used when money is contributed to an employee’s 401(k) account, but the employee hasn’t made their investment election. Employees can change this amount or opt out at any time.',
  },
  autoDeferralType: {
    id: 15,
    stepName: 'step_8',
    type: 'radio_button',
    name: 'autoDeferralType',
    user: {
      employer: {
        label: 'How would you like to handle employee contribution increases?',
        infoLabel:
          'Automatic contribution increases help make saving for retirement easy for your employees.',
      },
      advisor: {
        label:
          'How would the business owner like to handle employee contribution increases?',
        infoLabel:
          'Automatic contribution increases help make saving for retirement easy for your employees.',
      },
    },
    options: [
      {
        id: 1,
        user: {},
        label: 'Auto increase',
        info: 'By default, employee contributions automatically increase 1% each year up to 10%, helping employees save more for retirement. <em>Employees can opt out anytime.</em>',
        value: 'ESCALATING',
        valueFormatted: 'Auto increase',
        checked: false,
        icon: '',
        customStyle: '',
      },
      {
        id: 2,
        value: 'FLAT',
        user: {},
        label: 'Employee decides',
        info: 'Employees contribute only the default percentage of their income each year until they make a change.',
        valueFormatted: 'Employee decides',
        checked: false,
        icon: '',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Employee contribution increase',
    reviewScreenTitle: 'Employee contribution increase',
    description:
      "Business owners can choose to have their employees' contributions remain fixed unless they change them, or auto-escalate 1% each year up to 10% (ACA or EACA auto enrollment option) or 15% (QACA auto enrollment option). Auto escalation can help make it simpler for employees to save more for retirement.",
    optionDescription: `
    <p class="font-italic mb-0"><strong>You selected from these EMPLOYEE options:</strong></p>
    <ul class="font-italic">
      <li>
        <strong>Auto increase</strong> — By default, employee contributions automatically increase 1% each year up to 10%, helping employees save more for retirement. Employees can opt out anytime.
      </li>
      <li>
        <strong>Fixed</strong> — Employees contribute only the default percentage of their income each year until they make a change
      </li>
    </ul>
    `,
  },
  employerContributionMatch: {
    id: 12,
    stepName: 'step_10',
    type: 'radio_condensed',
    name: 'employerContributionMatch',
    user: {
      employer: {
        label:
          'As the business owner, are you okay with making matching contributions?',
        infoLabel:
          'Business owners have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible. Learn more about tax deductions at <a href="https://www.irs.gov/" target="_blank">IRS.gov</a>. With Simply Retirement by Principal<sup>®</sup>, employers also have profit-sharing flexibility, which provides employers the option to contribute company profits back to employees’ 401(k) plan accounts.',
      },
      advisor: {
        label: 'Would the business owner like to make matching contributions?',
        infoLabel:
          'Business owners have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible. Learn more about tax deductions at <a href="https://www.irs.gov/" target="_blank">IRS.gov</a>. With Simply Retirement by Principal<sup>®</sup>, employers also have profit-sharing flexibility, which provides employers the option to contribute company profits back to employees’ 401(k) plan accounts.',
      },
    },
    abandonProcessOnFalse: false,
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'yes',
        valueFormatted: 'Yes',
        checked: false,
        icon: 'icon-check-o',
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'no',
        valueFormatted: 'Not Contributing',
        checked: false,
        icon: 'icon-close-o',
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Contribution details',
    reviewScreenTitle: 'Matching contribution details',
    description:
      'Employers have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible (see IRS.gov for more information). There are two ways an employer can contribute — safe harbor and non-safe harbor. With safe harbor, the business owner will contribute more money, but they can bypass some of the compliance testing required by the IRS. With non-safe harbor, the business owner has the option to contribute less, but cannot bypass compliance testing required by the IRS. Compliance testing ensures that highly compensated employees don’t benefit more than others from the plan.',
  },
  autoEnrollment: {
    id: 'q6',
    stepName: 'step_6',
    type: 'radio_condensed',
    name: 'autoEnrollment',
    paradigmAlias: 'autoEnrollment',
    user: {},
    label: 'Do you want to use auto enrollment?',
    labelClass: 'fs-form-q fw-bold mb-3 text-center',
    infoLabel:
      'Automatic enrollment deductions are pre-tax. If an employee wants to make after-tax Roth contributions, they can opt out of auto-enrollment and choose a Roth contribution deferral amount.',
    heading: 'What’s automatic enrollment?',
    content:
      'Automatic enrollment is a plan provision which automatically enrolls eligible employees (participants) into the retirement plan at a specified salary deferral percentage unless the participant elects otherwise by completing an enrollment form in the enrollment meeting or on the participant website. This can help increase participation, simplify administration, and help employees save for retirement. As an added benefit, the SECURE 2.0 Act offers a new tax credit for small businesses when first implementing automatic enrollment using an eligible automatic contribution arrangement (EACA). It’s $500 annually for 3 years. This is in addition to the plan start-up credit. Consult with a tax advisor or other appropriate counsel for details.',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'yes',
        valueFormatted: 'Yes',
        checked: false,
        icon: 'icon-check-o',
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'no',
        valueFormatted: 'No',
        checked: false,
        icon: 'icon-close-o',
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
    sideCardTitle: 'Use auto enrollment',
    reviewScreenTitle: 'Use auto enrollment',
    optionDescription: '',
    description:
      "If auto enrollment is selected, once the employee meets the plan's age and service requirements, they will be automatically enrolled at a default pre-tax contribution percentage set by the business owner and begin making contributions to the plan immediately.* Participants can change their deferral or opt out at any time. They'll also have contributions directed to the plan's QDIA (Qualified Default Investment Alternative) unless they elect otherwise.  *Regardless of whether they meet the plan's age and service requirements, union employees, nonresident aliens, and independent contractors aren't allowed in the plan.",
  },
  safeHarbor: {
    id: 13,
    stepName: 'step_11',
    type: 'radio_button',
    name: 'safeHarbor',
    user: {
      employer: {
        label: 'Which type of matching contribution do you want to make?',
        infoLabel:
          'Contributing to your employees’ retirement is a great benefit to them. There are two ways you can contribute—safe harbor and non-safe harbor (safe harbor contributions will be allocated to participants each pay period). Both options provide tax deduction benefits.',
        heading: 'What’s safe harbor?',
        content:
          'Safe harbor is a type of 401(k) plan that allows you to bypass some of the compliance testing required by the IRS to make sure the plan is fair. In return, you’re required to help your employees save for retirement by making matching contributions to their 401(k) accounts. With safe harbor, you’ll contribute more money, but there’s less paperwork and administrative hassle. With a non-safe harbor plan, you have the option to contribute less, but you can’t bypass compliance testing required by the IRS. Check out the <a href="/safeharbor-explained">safe harbor resource page</a> for more details.',
      },
      advisor: {
        label:
          'Which type of matching contribution does the business owner want to make?',
        infoLabel:
          'Contributing to employees’ retirement is a great benefit to them. There are two ways the business owner can contribute—safe harbor and non-safe harbor (safe harbor contributions will be allocated to participants each pay period). Both options provide tax deduction benefits.',
        heading: "What's safe harbor?",
        content:
          'Safe harbor is a type of 401(k) plan that allows the business owner to bypass some of the compliance testing required by the IRS to make sure the plan is fair. In return, the business owner is required to help their employees save for retirement by making matching contributions to their 401(k) accounts. With safe harbor, the business owner may contribute more money, but there’s less paperwork and administrative hassle. With a non-safe harbor plan, the business owner has the option to contribute less, but they can’t bypass compliance testing required by the IRS. Check out the <a href="/safeharbor-explained">safe harbor resource page</a> for more details.',
      },
      anonymous: {
        label: 'Which type of matching contribution do you want to make?',
        infoLabel:
          'Contributing to your employees’ retirement is a great benefit to them. There are two ways you can contribute—safe harbor and non-safe harbor (safe harbor contributions will be allocated to participants each pay period). Both options provide tax deduction benefits.',
        heading: 'What’s safe harbor?',
        content:
          'Safe harbor is a type of 401(k) plan that allows you to bypass some of the compliance testing required by the IRS to make sure the plan is fair. In return, you’re required to help your employees save for retirement by making matching contributions to their 401(k) accounts. With safe harbor, you’ll contribute more money, but there’s less paperwork and administrative hassle. With a non-safe harbor plan, you have the option to contribute less, but you can’t bypass compliance testing required by the IRS. Check out the <a href="/safeharbor-explained">safe harbor resource page</a> for more details.',
      },
    },
    options: [
      {
        id: 1,
        user: {
          employer: {
            label: 'Safe harbor',
            info: 'You will generally contribute more, but IRS-approved guidelines allow you to bypass some compliance testing.',
          },
          advisor: {
            label: 'Safe harbor',
          },
        },
        value: 'safeHarbor',
        valueFormatted: 'Safe harbor',
        checked: false,
        icon: '',
        customStyle: '',
      },
      {
        id: 2,
        user: {
          employer: {
            label: 'Non-safe harbor',
            info: 'You have the option to contribute less, but cannot bypass compliance testing required by the IRS.',
          },
          advisor: {
            label: 'Non-safe harbor',
          },
        },
        value: 'nonSafeHarbor',
        valueFormatted: 'Discretionary Non-safe harbor',
        checked: false,
        icon: '',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Employer contribution type',
    description:
      'Employers have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible (see IRS.gov for more information).There are two ways an employer can contribute — Safe harbor and Non Safe harbor. With Safe harbor, you’ll contribute more money, but you can bypass some of the compliance testing required by the IRS. With non Safe harbor, you have the option to contribute less, but cannot bypass compliance testing required by the IRS. Compliance testing ensures that highly compensated employees do not benefit more than others from the plan.',
    optionDescription: `
      <p class="font-italic mb-0"><strong>You selected from these EMPLOYER options:</strong></p>
      <ul class="font-italic">
        <li>
          <strong>Safe harbor</strong>
          <ul>
            <li><strong>Stretch your match</strong> — Match the employee’s contribution 100% up to the first 3% of pay + match 50% of the next 2% of pay, up to 5% of pay.</li>
            <li><strong>Straight match</strong> — Match the employee’s contribution 100% up to 4% of pay.</li>
          </ul>
        </li>
        <li>
          <strong>Non-safe harbor</strong>
          <ul>
            <li>Match employee contributions dollar-for-dollar, 1%, 2%, 3% or 4% of pay</li>
            <li>No employer matching contributions</li>
          </ul>
        </li>
      <ul>
    `,
  },
  stretchSafeHarbor: {
    id: 14,
    stepName: 'step_12',
    type: 'radio_button',
    name: 'stretchSafeHarbor',
    user: {
      employer: {
        label: 'Which safe harbor match option do you want?',
        infoLabel:
          'Safe harbor contributions will be 100% vested. If you provide a profit-sharing contribution, it will align with the same vesting schedule. Vesting is the participant’s earned interest in an employer contribution.',
      },
      advisor: {
        label: 'Which safe harbor match option does the business owner want?',
        infoLabel:
          'Safe harbor contributions will be 100% vested. If the business owner provides a profit-sharing contribution, it will align with the same vesting schedule. Vesting is the participant’s earned interest in an employer contribution.',
      },
    },
    options: [
      {
        id: 1,
        value: '4',
        user: {
          employer: {
            label: 'Stretch your match',
            info: 'Match your employee’s contribution:<br /> <strong>Match 100% up to the first 3% of pay</strong><br /> <strong>+</strong><br /> <strong>Match 50% of the next 2% of pay, up to 5%</strong><br /> <em>(If your employee contributes 5%, your match would be 4% of pay.)</em>',
          },
          advisor: {
            label: 'Stretch your match',
            info: '<strong>Match 100% up to the first 3% of pay</strong><br /> <strong>+</strong><br /> <strong>Match 50% of the next 2% of pay, up to 5%</strong>',
          },
        },
        valueFormatted: 'Stretch your match',
        checked: false,
        icon: '',
        hasInfo: true,
        customStyle: '',
      },
      {
        id: 2,
        value: '5',
        user: {
          employer: {
            label: 'Straight match',
            info: 'Match your employee’s contribution:<br /> <strong>Up to 4% of pay: Match 100%</strong><br /> <em>(If your employee contributes 4%, your match would be 4% of pay.)</em>',
          },
          advisor: {
            label: 'Straight match',
            info: '<strong>Up to 4% of pay: Match 100%</strong>',
          },
        },
        valueFormatted: 'Straight match',
        checked: false,
        icon: '',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    hasConditional: false,
    hasError: false,
    valueFormatted: '3%',
    value: 3,
    description: '',
  },
  employerMatch: {
    id: 14,
    stepName: 'step_13',
    type: 'slider',
    name: 'employerMatch',
    tooltipNumber: 4,
    step: 1,
    labelClass: 'fs-form-q fw-bold text-center py-3',
    tooltipInfo: `For 4% safe harbor match could be a better option (allows you to bypass some IRS compliance tests)`,
    user: {
      employer: {
        label:
          'How much of your employees’ contributions would you like to match?',
        infoLabel:
          'You will have the option to match your employees’ contributions dollar for dollar, up to 10% of pay. <i>For example, if an employee contributes 5%, your match would be 5% of pay.</i>',
      },
      advisor: {
        label:
          'How much of the employees’ contributions would the business owner like to match?',
        infoLabel:
          'You will have the option to match your employees’ contributions dollar for dollar, up to 10% of pay. <i>For example, if an employee contributes 5%, your match would be 5% of pay.</i>',
      },
    },
    format: 'percentage',
    required: true,
    dirty: false,
    min: 1,
    thousandSeperator: false,
    max: 10,
    defaultValue: 3,
    sideCardTitle: 'Employer contribution match',
    disabled: false,
    valueFormatted: '3%',
    value: 3,
    hasConditional: false,
    hasError: false,
    description: '',
  },
  vestingSchedule: {
    id: 'default_step_12',
    type: 'dropdown',
    name: 'vestingSchedule',
    stepName: 'step_14',
    paradigmAlias: 'vestingScheduleProfit',
    user: {
      employer: {
        label:
          "Select your plan's vesting schedule for employer contributions (employer matching and discretionary profit sharing contributions)",
        infoLabel: '',
      },
      advisor: {
        label:
          'Select the plan’s vesting schedule for employer contributions (employer matching and discretionary profit sharing contributions)',
        infoLabel: '',
      },
    },
    heading: 'What’s a vesting schedule?',
    content:
      "A vesting schedule is the time frame it takes for employees to earn interest in contributions that a business owner makes to the employee's retirement plan. This is determined by the business owner and may be used as a retainment incentive. When an employee is 100% vested in their account balance, they earn 100% interest of it and the employer cannot forfeit, or take it back, for any reason. An employee must work 1,000 hours in a calendar year to receive a year of service towards vesting.",
    options: [
      {
        id: 1,
        user: {},
        label: 'Immediate = 100%',
        value: 'immediate',
        valueFormatted: 'Immediate',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
      {
        id: 2,
        user: {},
        label: '6-year graded = 0%, 20%, 40%, 60%, 80%, 100%',
        value: 'sixYearGraded',
        valueFormatted: '6-year graded',
        checked: false,
        icon: 'icon-calendar-o',
        customStyle: '',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Vesting schedule profit-sharing match',
    reviewScreenTitle: 'Vesting schedule for employer contributions',
    optionDescription: '',
    description:
      'If a specific vesting schedule was selected for this contribution type, it will be shown here.',
  },
  hardshipWithdrawals: {
    id: 'q13',
    type: 'radio_condensed',
    name: 'hardshipWithdrawals',
    paradigmAlias: 'hardshipWithdrawals',
    user: {},
    stepName: 'step_15',
    label: 'Will the plan allow for hardship withdrawals?',
    infoLabel:
      'Your plan will automatically allow participants to take a loan from their account.',
    heading: 'What are hardship withdrawals?',
    content:
      'This provision allows participants to withdraw eligible amounts from their 401(k) account to meet the needs of a financial hardship. The IRS sets certain guidelines for circumstances that may qualify as a hardship withdrawal, such as out-of-pocket medical expenses, down payment or repairs on a primary home, college tuition and related educational expenses, the threat of mortgage foreclosure or eviction, or funeral and burial expenses, and more. However, the plan terms ultimately set the specific criteria of a hardship withdrawals.</br></br> Participants must pay taxes on the amount they withdraw, plus a 10 percent penalty if they are under age 59&frac12;. Additional requirements may apply based on the provisions of the plan.<br />Participants can request to take a loan from their 401(k) plan balance and select a loan repayment schedule that best suits them. Only one loan may be outstanding at a time.',
    options: [
      {
        id: 1,
        user: {},
        label: 'Yes',
        value: 'yes',
        valueFormatted: 'Yes',
        checked: false,
        customStyle: 'radio_confirm',
      },
      {
        id: 2,
        user: {},
        label: 'No',
        value: 'no',
        valueFormatted: 'No',
        checked: false,
        customStyle: 'radio_cancel',
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Hardship withdrawals',
    reviewScreenTitle: 'Hardship withdrawals',
    optionDescription: '',
    description:
      'This provision allows participants to withdraw from their 401(k) account to meet the needs of a real financial emergency based on specific criteria as specified in the plan. Participants must pay taxes on the amount they withdraw, plus a 10% penalty if they are under age 59½. Additional requirements may apply based on the provisions of the plan.',
  },
  planEffectiveDate: {
    id: 17,
    stepName: 'step_16',
    type: 'date',
    name: 'planEffectiveDate',
    user: {
      onedigital: {
        label:
          'When do you want the ability for contributions to be made to the plan?',
        heading: 'Plan Effective Date',
        content:
          'The date you enter here will help us create your 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks.',
      },
      employer: {
        label:
          'When do you want the ability for contributions to be made to the plan?',
        heading: 'Plan Effective Date',
        content:
          'The date you enter here will help us create your 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks.',
      },
      advisor: {
        label:
          'When does the business owner want the ability for contributions to be made to the plan?',
        heading: 'Plan Effective Date',
        content:
          'The date entered here will help us create the 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks.',
      },
      tpa: {
        label:
          'When does the business owner want the ability for contributions to be made to the plan?',
      },
      anonymous: {
        label:
          'When do you want the ability for contributions to be made to the plan?',
        heading: 'Plan Effective Date',
        content:
          'The date you enter here will help us create your 401(k) plan with an accurate effective date. The plan effective date must be on or before the date when the first 401(k) contributions are withheld from employee paychecks.',
      },
    },
    inputLabel: 'Plan effective date',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    valueFormatted: '',
    hasConditional: false,
    hasError: false,
    reviewScreenTitle: 'Plan effective date',
    sideCardTitle: 'Plan effective date',
    description:
      'The plan effective date is the date on which eligible employees may begin participating in the plan.',
    info: `This date is passed the current year deadline for establishing a safe harbor plan. If you select a safe harbor plan, you must select a plan effective date no earlier than January 1, ${
      date.getFullYear() + 1
    }. <strong>For questions please call 844-804-0604.</strong>`,
    infoDate: `January 1, ${date.getFullYear() + 1}`,
  },
  planName: {
    stepName: '_tpa_step_4',
    type: 'text_field',
    name: 'planName',
    user: {},
    label: 'Plan name',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
    reviewScreenTitle: 'Plan name',
    sideCardTitle: 'Plan name',
    optionDescription: '',
    description: '',
  },
  planNumber: {
    stepName: '_tpa_step_4',
    type: 'number_field',
    name: 'planNumber',
    user: {},
    label: 'Plan number',
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasError: false,
    reviewScreenTitle: 'Plan number',
    sideCardTitle: 'Plan number',
    optionDescription: '',
    description: '',
  },
  investmentLineup: {
    id: 9,
    stepName: 'investmentLineup',
    type: 'radio_button_investment',
    name: 'investmentLineup',
    label:
      'Which lineup of investment options will be available to participants in the plan?',
    user: {
      advisor: {
        label:
          'Which lineup of investment options will be available to participants in the plan?',
      },
    },
    infoLabel:
      'Choose one investment option lineup. Up to three lineups are available, but only those your broker-dealer is licensed to offer will be shown. See <a href="https://simplyretirement.com/financial-professionals/investments" target="_blank">this page</a> for investments included in each lineup.',
    options: [
      {
        id: 1,
        user: {},
        label: 'Wilshire 3(38) Investment Lineup 1',
        value: 'fundLineup1',
        valueFormatted: 'Wilshire 3(38) Investment Lineup 1',
        checked: false,
        customStyle: '',
        heading: 'Wilshire 3(38) Investment Lineup 1',
        content:
          `<strong>Investment fees vary and range from 0.05% - 0.70% (as of ${asOfDate}), depending on the investment chosen.</strong>` +
          `<br/><a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PG5669-0&ty=VOP" target="_blank">Review investment option performance results and statistics.</a><br/><br/>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=4395&rtclss=97" target="_blank">LargeCap Growth I Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=80&rtclss=97" target="_blank">LargeCap S&P 500 Index Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=4397&rtclss=97" target="_blank">LargeCap Value III Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=4393&rtclss=97" target="_blank">SmallCap Growth I Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=191&rtclss=97" target="_blank">SmallCap S&P 600 Index Separte Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=6211&rtclss=97" target="_blank">SmallCap Value II Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=10020&rtclss=97" target="_blank">International Equity Index Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=156&rtclss=97" target="_blank">International I Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9320&rtclss=97" target="_blank">Overseas Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=10021&rtclss=97" target="_blank">Bond Market Index Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=60&rtclss=97" target="_blank">Core Plus Bond Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13647&rtclss=97" target="_blank">High Yield Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=6447&rtclss=97" target="_blank">Inflation Protection Separate Account CL Z</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9260&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2015</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9261&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2020</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9262&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2025</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9263&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2030</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9264&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2035</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9265&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2040</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9266&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2045</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9267&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2050</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9268&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2055</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13755&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2060</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=16896&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2065</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&symbol=T7097" target="_blank">Principal Lifetime Hybrid CIT 2070</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9269&rtclss=97" target="_blank">Principal Lifetime Hybrid Income CIT</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20" target="_blank">Morley Stable Value Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13646&rtclss=97" target="_blank">Global Real Estate Securities Separate Account CL Z</a></br>`,
        infoLink: 'Investment options for lineup 1 (0.05% - 0.70%)',
      },
      {
        id: 2,
        user: {},
        label: 'Wilshire 3(38) Investment Lineup 2',
        value: 'fundLineup2',
        valueFormatted: 'Wilshire 3(38) Investment Lineup 2',
        optionDescription:
          'Mutual funds are sold by prospectus. Please consider the investment objectives, risks, charges and expenses carefully before investing. The prospectus and, if available, the summary prospectus, contains this and other information. Read the prospectus and, if available, the summary prospectus carefully before investing.',
        description:
          'Mutual funds are sold by prospectus. Please consider the investment objectives, risks, charges and expenses carefully before investing. The prospectus and, if available, the summary prospectus, contains this and other information. Read the prospectus and, if available, the summary prospectus carefully before investing.',
        checked: false,
        customStyle: '',
        heading: 'Wilshire 3(38) Investment Lineup 2',
        content:
          `<strong>Investment fees vary and range from 0.03% - 0.89% (as of ${asOfDate}), depending on the investment chosen.</strong>` +
          `<br/><a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PG5670-0&ty=VOP" target="_blank">Review investment option performance results and statistics.</a><br/><br/>` +
          `<a href="https://www.morningstar.com/funds/xnas/wfspx/quote" target="_blank">iShares S&P 500 Index K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/bfgbx/quote" target="_blank">BlackRock Capital Appreciation K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/mkdvx/quote" target="_blank">BlackRock Equity Dividend K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/bmgkx/quote" target="_blank">BlackRock Mid-Cap Growth Equity K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/mjrfx/quote" target="_blank">BlackRock Mid Cap Value K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/bdbkx/quote" target="_blank">iShares Russell 2000 Small-Cap Index K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/btmkx/quote" target="_blank">iShares MSCI EAFE International Index K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/hncux/quote" target="_blank">Hartford International Growth R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/sidrx/quote" target="_blank">Hartford Schroders International Multi-Cap Value SDR Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/mkdcx/quote" target="_blank">BlackRock Emerging Markets K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/wfbix/quote" target="_blank">iShares US Aggregate Bond Index</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/mphqx/quote" target="_blank">BlackRock Total Return K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/brhyx/quote" target="_blank">BlackRock High Yield Bond K Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/bplbx/quote" target="_blank">BlackRock Inflation Protected Bond K Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9260&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2015</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9261&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2020</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9262&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2025</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9263&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2030</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9264&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2035</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9265&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2040</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9266&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2045</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9267&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2050</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9268&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2055</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13755&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2060</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=16896&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2065</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&symbol=T7097" target="_blank">Principal Lifetime Hybrid CIT 2070</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9269&rtclss=97" target="_blank">Principal Lifetime Hybrid Income CIT</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20" target="_blank">Morley Stable Value Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13646&rtclss=97" target="_blank">Global Real Estate Securities Separate Account CL Z</a></br>`,
        infoLink: 'Investment options for lineup 2 (0.03% - 0.89%)',
      },
      {
        id: 3,
        user: {},
        label: 'Wilshire 3(38) Investment Lineup 3',
        value: 'fundLineup3',
        valueFormatted: 'Wilshire 3(38) Investment Lineup 3',
        checked: false,
        optionDescription:
          'Mutual funds are sold by prospectus. Please consider the investment objectives, risks, charges and expenses carefully before investing. The prospectus and, if available, the summary prospectus, contains this and other information. Read the prospectus and, if available, the summary prospectus carefully before investing.',
        description:
          'Mutual funds are sold by prospectus. Please consider the investment objectives, risks, charges and expenses carefully before investing. The prospectus and, if available, the summary prospectus, contains this and other information. Read the prospectus and, if available, the summary prospectus carefully before investing.',
        customStyle: '',
        heading: 'Wilshire 3(38) Investment Lineup 3',
        content:
          `<strong>Investment fees vary and range from 0.05% - 0.74% (as of ${asOfDate}), depending on the investment chosen.</strong>` +
          `<br/><a href="https://secure02.principal.com/publicvsupply/GetFile?fm=PG5671-0&ty=VOP" target="_blank">Review investment option performance results and statistics.</a><br/><br/>` +
          `</br><a href="https://www.morningstar.com/funds/xnas/mfekx/quote" target="_blank">MFS Growth R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/meikx/quote" target="_blank">MFS Value R6 Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=80&rtclss=97" target="_blank">LargeCap S&P 500 Index Separate Accounts CL Z</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/otckx/quote" target="_blank">MFS Mid-Cap Growth R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/mvckx/quote" target="_blank">MFS Mid Cap Value R6 Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=191&rtclss=97" target="_blank">SmallCap S&P 600 Index Separate Account Z</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/rergx/quote" target="_blank">American Funds Euro Pacific Growth R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/mdizx/quote" target="_blank">MFS International Diversification R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/rllgx/quote" target="_blank">American Funds SMALLCAP World R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/rnwgx/quote" target="_blank">American Funds New World R6 Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=10020&rtclss=97" target="_blank">International Equity Index Separate Account-Z</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/rilfx/quote" target="_blank">American Funds Inflation Linked Bond R6</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/rbfgx/quote" target="_blank">American Funds Bond Fund of America R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/ltrhx/quote" target="_blank">Lord Abbett Total Return R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/lhyvx/quote" target="_blank">Lord Abbett High Yield R6 Fund</a></br>` +
          `<a href="https://www.morningstar.com/funds/xnas/rcwgx/quote" target="_blank">American Funds Capital World Bond R6 Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9260&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2015</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9261&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2020</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9262&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2025</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9263&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2030</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9264&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2035</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9265&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2040</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9266&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2045</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9267&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2050</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9268&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2055</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13755&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2060</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=16896&rtclss=97" target="_blank">Principal Lifetime Hybrid CIT 2065</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&symbol=T7097" target="_blank">Principal Lifetime Hybrid CIT 2070</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9269&rtclss=97" target="_blank">Principal Lifetime Hybrid Income CIT</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=9082&rtclss=20" target="_blank">Morley Stable Value Fund</a></br>` +
          `<a href="https://www.principal.com/InvestmentProfiles/index.faces?&inv=13646&rtclss=97" target="_blank">Global Real Estate Securities Separate Account CL Z</a></br>`,
        infoLink: 'Investment options for lineup 3 (0.05% - 0.74%)',
      },
      {
        id: 4,
        user: {},
        label: '3(38) investment lineup OneDigital 1',
        checked: false,
        optionDescription: '',
        description: '',
        customStyle: '',
        dirty: true,
        value: 'fundLineupOneDigital',
        valueFormatted: '3(38) investment lineup OneDigital 1',
        heading: '3(38) investment lineup OneDigital 1',
        content: `
          <strong>Investment fees vary and range from 0.02% - 0.8% as (of ${asOfDate}) depending on the investment chosen.</strong>
          <br/>See investment details here. (link: <a href="https://simplyretirement.com/onedigital/investments">https://simplyretirement.com/onedigital/investments</a>)`,
      },
    ],
    required: true,
    dirty: false,
    disabled: false,
    value: '',
    hasConditional: false,
    hasError: false,
    sideCardTitle: 'Lineup of investment options',
    reviewScreenTitle: 'Lineup of investment options',
    description: '',
  },
};

export default PLAN_DESIGN_INITIAL_STATE;
