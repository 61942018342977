import React from 'react';
import { HeroWrapper } from './index.styles';
import { asOfDate } from '../../../../Services/utilities';

const SingleArticle = () => (
  <div className="disclosures-page mb-5">
    <HeroWrapper className="hero mb-5 pt-5">
      <div className="container-fluid container-fluid--sitewidth">
        <div className="row justify-content-center py-5">
          <h1 className="fs-h1 text-center fw-bold py-5 col-xl-6 col-lg-8 col-md-10 mt-5">
            Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>{' '}
            Offers New Digital 401(k) Solution
          </h1>
        </div>
      </div>
    </HeroWrapper>
    <div className="container pb-5">
      <div className="row pb-5">
        <div className="col-md-9 mx-auto">
          <p>
            For today’s employees focused on making ends meet, saving for
            retirement can be a challenge. Many don’t have a plan or haven’t
            saved a fraction of what they’ll likely need to retire comfortably.
            Principal
            <small>
              <sup>®</sup>
            </small>{' '}
            has introduced a new solution, Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>
            , a digital offering designed to make 401(k) plans more accessible
            to businesses with fewer than 100 employees.
          </p>
          <h2>A simple online solution</h2>
          <p>
            With Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>
            , small business clients or financial professionals can create
            401(k) proposals quickly online through a straightforward process
            that reduces paperwork. No in-person meetings are required. And if
            help is needed, it’s just a phone call or email away.
          </p>
          <h2>Flat recordkeeping fees</h2>
          <p>
            Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>{' '}
            has flat recordkeeping fees that don’t change as clients’ plan
            assets change, helping business owners with predictability. Small
            businesses pay a $500 one-time setup fee for bundled plans and a
            $145 monthly recordkeeping fee. Each participating employee pays $6
            per month, unless the business owner chooses to pay this fee.
            <small>
              <sup>1</sup>
            </small>
          </p>
          <h2>Competitive investment options</h2>
          <p>
            A variety of investment options are included with Simply Retirement
            by Principal
            <small>
              <sup>®</sup>
            </small>
            . Principal
            <small>
              <sup>®</sup>
            </small>{' '}
            investment options offer strong performance with a competitive
            return, helping provide growth potential of employee contributions
            and any matching contributions the business owner provides.
          </p>
          <h2>Small business market potential</h2>
          <p>
            More than 5 million businesses across the U.S. don’t offer a
            workplace retirement plan today.
            <small>
              <sup>2</sup>
            </small>{' '}
            However, Principal research shows the workplace is among the best
            places to help make a positive impact on long-term savings habits.
          </p>
          <p>
            So, is now the right time to start a retirement plan? For some
            clients, the answer may be yes. Despite recent market volatility,
            85% of small businesses with one to 100 employees say they’re stable
            or growing, according to{' '}
            <a
              href="https://cts.businesswire.com/ct/CT?id=smartlink&url=https%3A%2F%2Fwww.principal.com%2Fabout-us%2Fnews-room%2Fnews-releases%2Fprincipal-survey-showcases-resilience-businesses-during-covid-19&esheet=52272306&newsitemid=20200825005187&lan=en-US&anchor=new+research&index=1&md5=e33be11197549c322ccaf016195d6ae7"
              target="_blank"
            >
              new research
            </a>{' '}
            from Principal.
            <small>
              <sup>3</sup>
            </small>
          </p>
          <p>
            &quot;The COVID-19 health crisis has changed the world in which we
            work, and we know that many small businesses have a lot to contend
            with. Their focus is on what’s most important right now, and we
            understand that,&quot; said Jerry Patterson, senior vice president
            of retirement and income solutions at Principal. &quot;When the
            situation allows, offering a retirement plan can help business
            owners take care of their employees in a new way, build positive
            morale and culture, and help retain and compete for qualified
            employees in the future.&quot;
          </p>
          <h2>Learn more</h2>
          <p>
            Simply Retirement by Principal
            <small>
              <sup>®</sup>
            </small>{' '}
            combines 75-plus years of expertise in retirement solutions and
            investments from Principal
            <small>
              <sup>4</sup>
            </small>{' '}
            with the simplicity and cost-effectiveness of the Ubiquity
            Retirement + Savings
            <small>
              <sup>®</sup>
            </small>{' '}
            recordkeeping platform and services.
          </p>
          <p>
            <strong>
              For more information and to create a proposal online, visit{' '}
              <a
                href="https://www.simplyretirement.com/financial-professionals"
                target="_blank"
              >
                SimplyRetirement.com
              </a>
              .
            </strong>
          </p>
          <p>
            <small>
              <sup>1</sup>
            </small>{' '}
            <small>
              Fees paid by the business owner are billed quarterly. Fees paid by
              participants are deducted monthly from participant accounts.
              Participant fees are charged if there is a $100 account balance,
              regardless of whether the participant is active or inactive.
              Custodial and investment fees are charged against participating
              employees’ accounts (those vary by investment and range from 0.03%
              - 0.86%, as of {asOfDate}). Your TPA fees and the financial
              professional fees are also additional and may be billed to the
              business owner. (Financial professional fees may be deducted from
              participant accounts.)
            </small>
          </p>
          <p>
            <small>
              <sup>2</sup>
            </small>{' '}
            <small>
              &quot;Millions of American Workers Still Lack Access to
              401(k),&quot; American Retirement Association, July 2019.
            </small>
          </p>
          <p>
            <small>
              <sup>3</sup> Principal Well-Being Index Survey of Business Owners
              with 1-100 employees, June 2020.
            </small>
          </p>
          <p>
            <small>
              <sup>4</sup>
            </small>{' '}
            <small>As of Dec. 31, 2019.</small>
          </p>
          <p>
            <small>
              Simply Retirement by Principal<sup>®</sup>
              401(k) plan recordkeeping, and administrative services are
              provided through Decimal, Inc. dba Ubiquity Retirement + Savings
              (&quot;Ubiquity&quot;). Ubiquity is not affiliated with any plan
              and investment administrative services provided through Principal
              Life Insurance Co. or affiliated with any company of the Principal
              Financial Group<sup>®</sup>. Principal makes available the
              investment options for customers to select through Simply
              Retirement by Principal. All other services are provided by
              service providers not affiliated with any company of the Principal
              Financial Group. Refer to related documents and agreements for
              more details on plan services available.
            </small>
          </p>
          <p>
            <small>
              The subject matter in this communication is educational only and
              provided with the understanding that neither Principal
              <sup>®</sup>
              or Ubiquity Retirement + Savings are rendering legal, accounting,
              investment advice or tax advice. You should consult with
              appropriate counsel or other financial professionals on all
              matters pertaining to legal, tax, investment or accounting
              obligations and requirements.&#8232;
            </small>
          </p>
          <p>
            <small>
              Insurance products and plan administrative services provided
              through Principal Life Insurance Co., a member of the Principal
              Financial Group<sup>®</sup>, Des Moines, Iowa 50392.
            </small>
          </p>
          <p>
            <small>&#8232;©2020 Principal Financial Services, Inc.</small>
          </p>
          <p>
            <small>1344533-09/2020 | 09/2020</small>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default SingleArticle;
