import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import HeaderSection from '../../../common/HeaderSection';
import QuestionSection from './QuestionSection';
import SectionSingleCol from '../../../common/SectionSingleCol';

import { LearnBasicsHeroImage } from '../../../ImagePaths';

const sectionHeader = {
  image: LearnBasicsHeroImage,
  title: 'Learn the basics',
  caption: `
    <p class="fs-display5">
      Setting up a retirement plan may sound daunting, but it doesn’t have to be.
    </p>
  `,
  alt: 'Business meeting with laptops',
  copy: `<p class="fs-h6 px-md-0"><strong>Simply Retirement by Principal<small><sup>®</sup></small> is designed to make it easy to set up a plan yourself, step by step.</strong> Once you understand the basics, it’s really just a matter of making choices that fit your situation. Here are a few things to consider as you look at your retirement plan options.</p>`,
};

const ltbDisclaimer1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*50% of the qualifying start-up costs for a new employee retirement plan:</span>50% of the qualified startup costs paid or incurred, but limited to the greater of (1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000. Qualified startup costs (1) In general “qualified startup costs” is ordinary and necessary expenses of an eligible employer which are paid or incurred in connection with -- (i) the establishment or administration of an eligible employer plan, or (ii) the retirement-related education of employees with respect to such plan. (2) Plan must have at least 1 participant: would not apply if plan does not have at least 1 employee eligible to participate who is not a highly compensated employee. Information about the SECURE 2.0 Act is educational only and provided with the understanding that Principal<small><sup>®</sup></small> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other financial professionals on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const ltbDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Eligible automatic contribution arrangement:</span> The SECURE 2.0 Act of 2019 provided an automatic enrollment one-time tax credit possible to be up to $500 per tax year for each year of the 3-taxable-year period beginning with the first taxable year for which the employer includes an eligible automatic contribution arrangement. This credit is for plans that include the eligible automatic contribution arrangement (EACA) feature only. Information about the SECURE Acts is educational only and provided with the understanding that Principal<small><sup>®</sup></small> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other financial professionals on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const ltbDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Workers are more likely to save for retirement when they have access to a 401(k) or similar plan through their employer:</span>Principal<small><sup>®</sup></small> Retirement Security Survey – October 2022. </p><p class="fs-disclaimer">As part of the Simply Retirement by Principal<small><sup>®</sup></small> product, Wilshire Advisors LLC is the fiduciary responsible for the selection and monitoring of the investments.</p>`;
const ltbTaxCreditDisclaimer = `<p class="fs-disclaimer-print">Tax credit is for contributions for employees who earn no more than $100,000 (adjusted for cost of living), the credit is 100% of the contribution for the first 2 years with a 25% reduction each year, and credit can’t also be counted for the start-up tax credit.</p>`;
const ltbStartupCostsDisclaimer = `<p class="fs-startup-costs-disclaimer">Start-up tax credit modification: Small employers with 50 or fewer employees may apply 100% of qualified start-up costs towards the tax credit formula (up to $5,000 per year). </p><p>New tax credit for start-up plans offering employer contributions: A tax credit equal to the applicable percentage of employer contributions, capped at a maximum of $1,000 per employee.<ul><li>Applicable to small employers with 50 or fewer employees.</li><li>For employees with 51-100 employees: The credit is phased out by reducing the amount of credit each year 2% for each employee in excess of 50.</li></ul><b>Applicable Percentage:</b><p>1st and 2nd year = 100%, 3rd year = 75%, 4th year = 50%, 5th year = 25%, 6th year = 0% </p><p>No contributions may be counted for employees with wages in excess of $100,000 (inflation adjusted). If taking advantage of this tax credit, employer contributions may not also be counted towards “start-up costs” in the start-up tax credit calculation.</p></p>`;

const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${ltbDisclaimer1}
     ${ltbDisclaimer2}
     ${ltbDisclaimer3}
     ${ltbTaxCreditDisclaimer}
     ${ltbStartupCostsDisclaimer}
    </div>
  </div>
`;

const LearnTheBasicsContainer = ({
  applicationPanelInfo,
  putApplicationPanelInfo,
}) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'ltb-disclaimer-1',
      'ltb-disclaimer-2',
      'ltb-disclaimer-3',
      'ltb-tax-credit-disclaimer',
      'ltb-startup-costs-disclaimer',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'ltb-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: ltbDisclaimer1,
        });
        break;

      case 'ltb-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: ltbDisclaimer2,
        });
        break;

      case 'ltb-disclaimer-3':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: ltbDisclaimer3,
        });
        break;
      case 'ltb-tax-credit-disclaimer':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: ltbTaxCreditDisclaimer,
        });
        break;
      case 'ltb-startup-costs-disclaimer':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: ltbStartupCostsDisclaimer,
        });
        break;

      default:
        break;
    }
  };
  return (
    <div className="learn-basics-page">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />
      <QuestionSection putApplicationPanelInfo={putApplicationPanelInfo} />
      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
    </div>
  );
};

LearnTheBasicsContainer.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LearnTheBasicsContainer);
