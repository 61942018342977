import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderSection from '../HeaderSection';
import SectionSingleCol from '../SectionSingleCol';
import SectionEnrollmentFees from './SectionEnrollmentFees';
import SectionPlanFeatures from './SectionPlanFeatures';

import { FaqHeroImage } from '../../ImagePaths';
import FormDataRedux from '../../Redux/FormDataRedux';
import { asOfDate } from '../../Services/utilities';

const sectionHeader = {
  image: FaqHeroImage,
  title: 'Frequently asked questions',
  caption: `
    <p class="fs-display5">
      Looking for more details? Here are answers to some of the questions you may have about this retirement plan solution.
    </p>
  `,
  alt: 'Man working on a computer.',
  copy: ``,
};

const sectionEnrollmentFees = `
<h4 class="fs-h3 my-3 pt-5">
  Enrollment and fees
</h4>
<p class="fs-h2 text-purple"><strong>What if I (or my employees) don’t want to be automatically enrolled in the plan?</strong></p>
<p class="fs-p mb-4">
  No problem. The automatic enrollment feature is optional. If you choose the option for employees to be automatically enrolled at a default pre-tax contribution percentage (set by the business owner), employees can change this amount or opt out at any time. Participants can opt out by choosing the opt-out option in their online account under “Manage Deferrals.” Choosing the automatic enrollment feature can help increase participation, simplify administration, and help employees save for retirement.
</p>
<p class="fs-h2 text-purple"><strong>What happens if my company grows beyond 100 employees?</strong></p>
<p class="fs-p mb-4">
  When your eligible employees exceed 100, your plan may become subject to an independent annual plan audit. When your plan becomes subject to audit requirements, we will inform you and work alongside you and the auditors to complete this annual audit.
</p>
<p class="fs-h2 text-purple"><strong>What if an employee leaves their job? Is there a penalty?</strong></p>
<p class="fs-p mb-4">
  If an employee leaves your business, they’ll need to decide what they want to do with their vested 401(k) account. They can keep the money in the plan, move it to a new employer’s plan, move it to a self-directed retirement account (also known as a rollover IRA), or cash out.* Former employees may stay invested in your 401(k) plan indefinitely if their balance is at least $5,000.
</p>
<p class="fs-disclaimer">*If an employee takes a distribution (“cashes out”) before reaching age 59 1&frasl;2, the IRS generally requires automatic withholding of 20% of a 401(k) early withdrawal, which will be used to offset whatever they owe in federal taxes. They may owe applicable state and local taxes as well. The IRS will also assess a 10% early withdrawal penalty if applicable.</p>
<p class="fs-h2 text-purple"><strong>As a business owner, do I have to participate?</strong></p>
<p class="fs-p mb-4">
  No. However, it’s a great way to receive the same benefits as your employees. For example, you can defer paying taxes on any pre-tax contributions you make, and you can take advantage of any matching contributions that are offered. You may contribute up to the maximum per IRS limits for the applicable tax year. Note: You will be automatically enrolled unless you opt out by choosing the opt-out option in your online account.
</p>
<p class="fs-h2 text-purple">
  <strong>What are the plan fees, and who pays them?</strong>
</p>
<p class="fs-p"><strong>The business owner pays:</strong></p>
<ul>
<li>
  <p class="fs-p mb-0">
    <strong>$500 one-time</strong> setup fee for bundled plans.
  </p>
</li>
<li>
  <p class="fs-p mb-0">
    <strong>$145 per month</strong> recordkeeping fee ($435 billed quarterly) if working with a third party administrator (TPA) or $185 per month bundled recordkeeping fee ($555 billed quarterly) if not working with a TPA.
  </p>
</li>
</ul>
<p class="fs-p"><strong>The participants pay:</strong></p>
<ul>
<li>
  <p class="fs-p">
    <strong>$6 per participant, per month</strong> recordkeeping fee for each participant with an account balance over $100. This is deducted from participant accounts monthly. You (the business owner) may elect to pay it quarterly on behalf of the participants.
  </p>
</li>
<li>
  <p class="fs-p">
    <strong>Financial professional and/or TPA fees,</strong> if you choose to work with one. If the financial professional or TPA bills you (the business owner) directly, you will pay this fee. If the financial professional chooses to be paid out of plan assets, this fee will be deducted quarterly from participant balances in the plan on a pro-rated basis.
  </p>
</li>
<li>
  <p class="fs-p">
    <strong>Custodial and investment fees,</strong> which are charged against participating employees’ accounts (those vary by investment and range from 0.03% – 0.86%, as of ${asOfDate}). Custodial fees are up to 0.055%.
  </p>
</li>
</ul>
<p class="fs-h2 text-purple"><strong>How do contributions work?</strong></p>
<p class="fs-p">Contributions are deposited by the plan sponsor (you) or the employees (participants). While employee contributions are elective to the employee, some employer contributions may be required by the terms of your plan.</p>
<ul>
<li>
  <p class="fs-p">
    <strong>Participants</strong> contribute their elected deferral (a percentage of their pay they have selected) each pay period.
  </p>
</li>
<li>
  <p class="fs-p">
    If offered, <strong>business owners</strong> can match up to 10% of participant contributions for the plan each pay period.
  </p>
</li>
</ul>
<p class="fs-h2 text-purple"><strong>How and when are the fees billed?</strong></p>
<p class="fs-p ">
  On the first day of the month after you sign the required documents and purchase the plan, the one-time $500 setup fee and your first three months of plan fees will be charged to the payment method you have entered. If your purchase is completed on the first day of the month, you will be charged the same day. The same payment method will be charged on the first day of the month every three months thereafter for three months of the recordkeeping fee ($145/month for a total of $435 if working with a TPA, or $185/month for a total of $555 if not working with a TPA), and if elected, three months of the $6/month per participant fee ($6 x number of participants x 3 months). If applicable, the financial professional’s fee will be deducted quarterly from participant accounts. TPA fees will be billed directly to the business owner (not deducted from participant accounts).
</p>
<p class="fs-p mb-4">
  Specific deadlines apply to safe harbor plans, and this may impact billing and subscription effective dates.
</p>
`;

const sectionGettingForFees = `
  <p class="fs-h2 text-purple">
    <strong>What exactly am I getting for the fees?</strong>
  </p>
  <p class="fs-p mb-4">Recordkeeping fees cover the cost of maintaining the plan. This includes services such as:</p>
  <ul>
    <li>
      <p class="fs-p">Annual compliance testing</p>
    </li>
    <li>
      <p class="fs-p">Government filing and reporting of required documentation</p>
    </li>
    <li>
      <p class="fs-p">Ongoing recordkeeping (e.g., tracking which employees are participating and the amount they’ve invested in each of the plan funds)</p>
    </li>
    <li>
      <p class="fs-p">Automated participant signup and onboarding</p>
    </li>
    <li>
      <p class="fs-p">An online platform for plan administration and participant account management 24/7</p>
    </li>
    <li>
      <p class="fs-p">A fund lineup selected and managed by Wilshire Advisors LLC, a registered 3(38) investment fiduciary</p>
    </li>
    <li>
      <p class="fs-p">A dedicated phone number for questions</p>
    </li>
    <li>
      <p class="fs-p">Bond (insurance) coverage of the plan’s assets up to $250,000*</p>
    </li>
    <li>
      <p class="fs-p">Access to a comprehensive financial wellness platform that provides tools and resources to employees to better manage their current and future financial well-being.</p>
    </li>
  </ul>
  <p class="fs-p mb-4">Investment fees cover the cost of managing the investments, including operating expenses, management fees, and administrative fees. Custodial fees cover the services of the financial custodian, such as holding plan assets in an account for safekeeping, collecting dividend and interest income, and providing account statements.</p>
  <p class="fs-disclaimer">*For the first three years, Ubiquity Retirement + Savings will cover the cost of an ERISA fidelity bond up to $250,000 in assets (which equals a $25,000 bond). After three years, a renewal letter will be sent and the business owner can choose to accept the renewal at their own expense, or consult with their financial professional and/or TPA in choosing another bond provider.</p>
`;

const sectionCreatePlan = `
<h4 class="fs-h3 mb-3">
  Creating a plan design/proposal
</h4>
<p class="fs-h2 text-purple"><strong>What’s the “Simply Retirement by Principal<sup><small>®</small></sup> planner” and who is it for?</strong></p>
<p class="fs-p mb-4">
  The “planner” is a simple series of questions where you, your financial professional and/or your TPA will fill in a few details about your business and make selections to create a Simply Retirement by Principal<sup><small>®</small></sup> plan proposal. Anyone can use the planner to see what a plan might look like for a business—just choose your role (business owner, financial professional, TPA or “just looking”) and answer the questions. The plan proposal you create can be saved, shared, and edited up to 60 days after it was created. Keep in mind that financial professionals, TPAs, and business owners can create a plan design, but <strong>only a business owner can complete the purchase.</strong>
</p>
<p class="fs-h2 text-purple"><strong>How long does it take to answer the questions and create a plan/proposal?</strong></p>
<p class="fs-p">
  On average, 15 to 20 minutes is a reasonable estimate, but your experience may vary. There are about 15 questions to answer (a few more if you are a financial professional or TPA). You may need to take some time to look up information, consult another person on your team, or think about some of your selections in the process.
</p>
<p class="fs-p mb-4">
  If you close out of the planner before saving your plan, you’ll see a button at the bottom of your screen that says “You have a plan in progress.” As long you have the website open, you can click this button to pick up where you left off. If you want to leave the site and come back later, you’ll need to finish the questions, save your plan design, and save the link to be able to return and edit your answers. Plan designs are saved for 60 days.
</p>
<p class="fs-h2 text-purple"><strong>How do I find a financial professional and/or a TPA?</strong></p>
<p class="fs-p mb-4">
  If you aren’t engaged with a financial professional or TPA, but are interested in working with one, please contact the Retirement Sales Support Team at <a href="mailto:RetirementSalesSupportTeam@exchange.principal.com">RetirementSalesSupportTeam@exchange.principal.com</a> for assistance.
</p>
<p class="fs-h2 text-purple"><strong>Is there someone I can contact if I have questions?</strong></p>
<p class="fs-p mb-4">
 Yes. You can call our dedicated phone number: <a href="tel:844-804-0604">844-804-0604</a>, or email  <a href="mailto:info@simplyretirement.com">info@simplyretirement.com</a>.
</p>
<p class="fs-h2 text-purple"><strong>What credentials does Simply Retirement by Principal<sup><small>®</small></sup> require for financial professionals to receive compensation through the retirement plan?</strong></p>
<p class="fs-p mb-4">Simply Retirement by Principal<sup><small>®</small></sup> is a group variable annuity product offering which requires both FINRA registration and variable annuity line insurance licensing in order for a financial professional to receive compensation through the retirement plan. Principal will research required financial professional licensing and registration beginning with the National Producer Number (NPN) which all variable annuity insurance licensed professionals are assigned.</p>
`;

const sectionServiceProviders = `
<h4 class="fs-h3 mb-3">
  Service providers
</h4>
<p class="fs-h2 text-purple"><strong>I’ve noticed Principal, Ubiquity Retirement + Savings<sup><small>®</small></sup>, and Wilshire Advisors LLC mentioned on this site. What are their roles and how do they fit in?</strong></p>
<p class="fs-p">
 Principal is the distributor and can be the investment manager of some of the investment options for Simply Retirement by Principal<sup><small>®</small></sup>. Ubiquity provides the plan recordkeeping and administrative services once you purchase the plan. You and your participating employees will also use Ubiquity’s digital platform to manage your plan. You can read more about our collaboration <a href="/business-owners/about">here</a>.
</p>
<p class="fs-p mb-4">x
  Wilshire & Associates, Inc. will be the plan’s 3(38) investment fiduciary responsible for selecting and monitoring the plan’s investment options. Wilshire Advisors LLC is an independent investment management firm that manages capital for more than 600 institutional investors globally, representing more than $8 trillion of capital.
</p>
<p class="fs-h2 text-purple"><strong>Who handles testing and reporting?</strong></p>
<p class="fs-p mb-4">
 Ubiquity's team of compliance experts perform annual nondiscrimination testing. Ubiquity also helps business owners stay on top of annual government form filings and documentation—like IRS Form 5500, 8955-SSA preparation and filing, participant disclosures, and annual plan notifications.
</p>
`;

const sectionPlanFeatures = `
<h4 class="fs-h3 mb-3">
  Plan features
</h4>
<p class="fs-h2 text-purple">
  <strong>What makes Simply Retirement by Principal<sup><small>®</small></sup> a different kind of solution?</strong>
</p>
<p class="fs-p">Simply Retirement by Principal<sup><small>®</small></sup> is the first 100% digital 401(k) plan solution from Principal<sup><small>®</small></sup>. Here are a few key features that set it apart:</p>
<ul>
<li class="fs-p">
    It's designed for small businesses with fewer than 100 employees.
</li>
<li class="fs-p">
    It's all online. From designing and purchasing your plan to e-signing documents to onboarding employees and ongoing plan administration, everything can be done on a website—where and when it's convenient for you, as well as your financial professional and/or TPA—if you are working with one.
</li>
<li class="fs-p">
    It has affordable, flat-fee pricing. This solution is the same price for all customers, period. And that price doesn't change as your plan assets change.
</li>
<li class="fs-p">
    It's created for simplicity. Streamlined selections and investment choices give you options without overcomplicating the process. And with less time-consuming paperwork, financial professionals and TPAs can focus on providing education and service.
</li>
</ul>
<p class="fs-h2 text-purple"><strong>What’s the value of a 401(k) plan to my employees?</strong></p>
<p class="fs-p mb-4">
  First and foremost, it gives them an easy way to save for retirement. Contributions are automatically deducted from employee paychecks each pay period, so they don't have to budget separately for setting money aside. They also have an opportunity to take advantage of any matching contributions you may offer, making it possible for them to save more. Plus, pre-tax contributions are deducted from paychecks before income taxes, reducing individual taxable income, and taxes are deferred on any investment gains and earnings until the money is withdrawn in retirement.
</p>
<p class="fs-h2 text-purple"><strong>Do participants have the option to defer Roth contributions with this plan?</strong></p>
<p class="fs-p mb-4">
  Yes. By default, auto-enrollment contributions are only pre-tax contributions, but participants can choose to make both pre-tax and Roth contributions to their 401(k) plan from their dashboard once they set up their online account. If you’re working with a TPA, your plan provisions for auto enrollment and Roth contributions may vary.
</p>
<p class="fs-h2 text-purple"><strong>What’s the vesting schedule for this plan?</strong></p>
<p class="fs-p mb-4">
  Business owners can choose to have their employees 100% vested in the 401(k) plan immediately or on a 6-year graded vesting schedule. However, if you’re working with a TPA, your plan’s vesting options may vary.
</p>
<p class="fs-h2 text-purple"><strong>Are loans available with this plan?</strong></p>
<p class="fs-p mb-4">
  Yes. Participants can request to take one loan from their 401(k) plan balance and select a loan repayment schedule that best suits them but is no longer than five years. Participants may repay a loan to up to 30 years if the proceeds are used to purchase their primary residence. Only one loan may be outstanding at a time. Loan repayments are made via after-tax payroll deductions. The interest portion of the loan payment is applied to the participant’s account. If you’re working with a TPA, your plan may vary.
</p>
<p class="fs-h2 text-purple"><strong>Are hardship withdrawals available with this plan?</strong></p>
<p class="fs-p mb-4">
  Yes. Business owners can choose to have the plan allow for hardship withdrawals. This provision allows participants to withdraw eligible amounts from their 401(k) account to meet the needs of a financial hardship. Participants must pay taxes on the amount they withdraw, plus a 10 percent penalty if they are under age 59 1&frasl;2. Additional requirements may apply based on the provisions of the plan.
</p>
<p class="fs-h2 text-purple"><strong>What is payroll integration and how does it work?</strong></p>
<p class="fs-p mb-4">
  Payroll integration connects your payroll provider directly to your 401(k) plan. Depending on your payroll provider, each pay period, they will automatically share your employee's contribution information to the Simply Retirement by Principal<small><sup>®</sup></small>/Ubiquity recordkeeping system. Ubiquity’s Payroll (k)oncierge service can help you get started.
</p>
<p class="fs-h2 text-purple"><strong>How can I get more information on the investments?</strong></p>
<p class="fs-p mb-4">
  See <a href="/business-owners/investments">this page</a>. There’s a listing of investment options under each asset class, and you can click any investment option for fact sheets and other details. If you’re working with a financial professional, they may assist you with selecting an alternative investment option lineup for your plan. Talk to your financial professional for more information.
</p>
<p class="fs-h2 text-purple"><strong>What is Elevate by Principal?</strong></p>
<p class="fs-p mb-4">
  With a Simply Retirement by Principal<sup><small>®</small></sup> plan, your client has access to Elevate by Principal, a powerful network, resource, and team of people in their corner. From data-driven insights to deep discounts on products and services, that a business owner uses every day, Elevate by Principal can provide what your client needs to take their business to the next level.
</p>
`;

const sectionOnBoarding = `
<h4 class="fs-h3 mb-3">
  Onboarding
</h4>
<p class="fs-h2 text-purple">
  <strong>What happens after I commit to purchase the plan? What can I expect during the onboarding process and beyond?</strong>
</p>

<p class="fs-p mb-4">
  When you click the “Complete your purchase” button, you will be redirected to the Ubiquity Retirement + Savings<sup><small>®</small></sup> (Ubiquity) website where you will:
</p>
<div class="container container-fluid">
  <div class="row my-5 justify-content-center">
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Set up login credentials.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Provide additional details about your business.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Pay the one-time non-refundable $500 setup fee.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Sign required documents electronically.
      </p>
    </div>
  </div>
</div>
<p class="fs-p">
  After completing these steps, you will enter the “Implementations” stage
  where you will work with your dedicated specialist at Ubiquity to set up
  your plan over 3 phone calls. Here’s what you can expect:
</p>

<ul>
<li class="fs-p">
  You’ll get an email from “401(k) Implementations”
  &lt;newplan@myubiquity.com&gt; to set up a 50-minute introductory call
  with your dedicated specialist. Please schedule your call as soon as
  possible, preferably within the next five business days!
</li>
<li class="fs-p">
  Your specialist will help you add your employees on your 2nd call.
</li>
<li class="fs-p">
  Your specialist will assist you in funding your first contribution on
  your 3rd and final implementation call. After you process the first
  payroll and start funding the plan, you’ll be all set and your plan is
  active!
</li>
</ul>
<p class="fs-p">After you process the first payroll and start funding the plan, you’ll be all set! Your plan will be considered active.</p>
`;

const sectionOnBoardingTPA = `
<h4 class="fs-h3 mb-3">
  Onboarding
</h4>
<p class="fs-h2 text-purple">
  <strong>
    Watch for an email invitation when you save your first Simply Retirement
    by Principal<sup><small>®</small></sup> plan proposal. The email will include a link to the Ubiquity
    Retirement + Savings<sup><small>®</small></sup>
    website, where you can:
  </strong>
</p>

<div class="container container-fluid">
  <div class="row my-5 justify-content-center">
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Register with Ubiquity and establish login credentials.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Check out your dashboard.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Upload Summary Plan Description (SPD) for the new plan (TPAs only).
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Manage your Simply Retirement by Principal
        <sup>
          <small>®</small>
        </sup>{' '}
        proposals and clients.
      </p>
    </div>
  </div>
</div>
<p class="fs-p">
  You’ll also be copied on key email notifications to your clients as they
  complete the purchase and plan setup process.
</p>
`;

const sectionSupport = `
<h4 class="fs-h3 mb-3">
  Support
</h4>
<p class="fs-h2 text-purple">
  <strong>How can I get support to set up my plan?</strong>
</p>
<p class="fs-p">Every new plan is assigned an Implementation Specialist by Ubiquity to ensure the plan is set up correctly, and to help process the first employee census and payroll contribution.</p>
<p class="fs-p mb-0"><strong>Implementation Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:newplan@myubiquity.com">newplan@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:855-910-4015">855-910-4015</a></p>
<p class="fs-p mb-4">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-h2 text-purple">
  <strong>How do I get support after my plan is set up?</strong>
</p>
<p class="fs-p">The following Customer Success Teams are available:</p>
<p class="fs-p mb-0"><strong>Employer Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569">855-700-5569</a></p>
<p class="fs-p">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-p mb-0"><strong>Employee Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:info@myubiquity.com">info@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18554014357">855-401-4357, Option 1</a></p>
<p class="fs-p">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-p mb-0"><strong>Financial Professional Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569">855-700-5569</a></p>
<p class="fs-p">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
<p class="fs-p mb-0"><strong>TPA Support</strong></p>
<p class="fs-p mb-0"><a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a></p>
<p class="fs-p mb-0"><a href="tel:+18557005569">855-700-5569</a></p>
<p class="fs-p mb-5 pb-5">Monday–Friday, 6 a.m. – 5 p.m. PST</p>
`;
const Faq = ({ putApplicationPanelInfo, isFp = false, isSMB = false }) => (
  <div className="about-page mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
    />

    {isFp && (
      <SectionSingleCol
        copy={sectionEnrollmentFees}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />
    )}
    {isSMB && (
      <SectionEnrollmentFees
        putApplicationPanelInfo={putApplicationPanelInfo}
      />
    )}

    <SectionSingleCol
      copy={sectionGettingForFees}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-2"
    />

    <SectionSingleCol
      copy={sectionCreatePlan}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-2"
    />

    <SectionSingleCol
      copy={sectionServiceProviders}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-2"
    />

    {isFp && (
      <SectionSingleCol
        copy={sectionPlanFeatures}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />
    )}
    {isSMB && (
      <SectionPlanFeatures putApplicationPanelInfo={putApplicationPanelInfo} />
    )}

    {isFp && (
      <SectionSingleCol
        copy={sectionOnBoardingTPA}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />
    )}

    {isSMB && (
      <SectionSingleCol
        copy={sectionOnBoarding}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />
    )}

    <SectionSingleCol
      copy={sectionSupport}
      columns="col-12 col-lg-10 mx-auto"
      className="pt-0 pb-5"
    />
    <span className="d-none">1216092-062020</span>
  </div>
);

Faq.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  isFp: PropTypes.bool,
  isSMB: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
