import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import PurpleSection from './PurpleSection';
import Description from './DescriptionSection';
import TableSection from './TableSection';

import {
  Plan401kHeaderImage,
  IconCommentQuestionO,
  CheckCircle,
} from '../../../ImagePaths';

const sectionHeader = {
  image: Plan401kHeaderImage,
  title: 'The simple 401(k) plan for small businesses',
  caption: `
    <p class="fs-display5">
      401(k) plans are a popular option for small businesses because of
              the flexibility they offer.
    </p>
  `,
  alt: 'Employee taking notes at a meeting.',
  copy: `
    <p class="fs-h6 px-md-0">
      <strong>
        With Simply Retirement by Principal<small><sup>®</sup></small>, you’ll get that flexibility and more.
      </strong>
      Our 401(k) plan solution is designed to make plan management easy
      for you while providing a way for your employees to save for their
      retirement.
    </p>`,
};

const whyConsiderSection = `
  <img src=${IconCommentQuestionO} alt="" width="60" class="mb-3" />
  <h4 class="fs-display4 text-purple fw-bold">
    Why consider a 401(k) plan?
  </h4>
  <p class="fs-h4">
    A 401(k) plan is a valuable employee benefit that can help you recruit
    and retain top talent. It’s also an effective way to help your
    employees save for the future.
  </p>
  <h6 class="fs-h6 fw-bold mb-0">What’s a 401(k) plan?</h6>
  <p class="fs-p">
    401(k) plans allow employees to set aside a portion of their
    compensation. Business owners can make contributions to the employees' retirement plans.
  </p>
  <p class="fs-p">
    Normally, a 401(k) plan is designed to help employees maximize their
    retirement savings by deferring the payment of income taxes until they
    withdraw the money in retirement. Roth contributions, however, are
    another option that allows employees to pay taxes before contributing
    to their retirement so they don’t have to pay taxes when they withdraw
    the money as long as distribution requirements are met.
  </p>
  <h6 class="fs-h6 fw-bold mb-0">
    A 401(k) plan offers the flexibility to design a plan with:
  </h6>
  <ul style={{ paddingLeft: '1.2rem' }}>
    <li class="fs-p">
      Tax benefits for your business and your employees
    </li>
    <li class="fs-p">Pre-tax salary deferrals</li>
    <li class="fs-p">
      Profit sharing with the option to offer matching contributions
    </li>
    <li class="fs-p">
      The option for your employees to request a loan from their 401(k)
      plan balance
    </li>
    <li class="fs-p">
      Convenient automatic payroll deductions for employee contributions
    </li>
  </ul>
`;

const sectionPurple = `
  <h3 class="fs-h3 fw-bold text-white pb-3">
    Tax credits are available for small businesses just starting a new
    retirement plan.
  </h3>
  <p class="text-white fs-p fw-bold">
    New SECURE 2.0 Act legislation could help offset some of your plan start-up costs.
  </p>
  <p class="text-white fs-p">
   The SECURE 2.0 Act allows small businesses with no more than 50 employees to claim a tax credit of 100% of the qualifying start-up costs for a new employee retirement plan (<span id="retirement-plans-employer-startup-costs-disclaimer" class="text-white text-underline p-0 no-border-button">up to $5,000 per tax year for the first three years</span><sup><small>*</small></sup>). SECURE Act of 2019 allows a tax credit of 50% of the qualifying start-up costs for a new employee retirement plan for 3 years if the employer has 51-100 employees (maximum $5,000 a year).
   </p>
  <p class="text-white fs-p">
    There’s also an extra tax credit for 5 years of up to $1,000 per employee a year for employer contributions made if employers have no more than 50 employees. For employers with more than 51-100 employees, the credit is reduced by 2% for each employee in excess of 50. Under SECURE of 2019, an employer may also be eligible to claim up to $500 tax credit for including an <span id="retirement-plans-employer-eligible-contribution" class="text-white text-underline p-0 no-border-button">eligible automatic contribution arrangement,</span> which is an optional feature of the Simply Retirement by Principal<small><sup>®</sup></small> 401(k) plan. Plus, matching contributions you make to employee retirement accounts as noted above can be tax-deductible. For example, a company with 25 employees can see the 2022 plan start-up fee change from $2,200 per year to potentially offset the expenses by 100% with tax credits. See your tax advisor for guidance on how these credits may apply.
  </p>
`;

const businessOwnerRetirementPlansDisclaimer1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*Up to $5,000 per tax year for the first three years:</span>50% of the qualified startup costs paid or incurred, but limited to the greater of (1) $500 or (2) the lesser of (a) $250 for each non-highly compensated employee who is eligible to participate in the plan or (b) $5,000. Qualified startup costs (1) In general “qualified startup costs” is ordinary and necessary expenses of an eligible employer which are paid or incurred in connection with –  the establishment or administration of an eligible employer plan, or (ii) the retirement-related education of employees with respect to such plan. (2) Plan must have at least 1 participant: would not apply if plan does not have at least 1 employee eligible to participate who is not a highly compensated employee. Information about the SECURE 2.0 Act is educational only and provided with the understanding that Principal<small><sup>®</sup></small> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other financial professionals on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const businessOwnerRetirementPlansDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Automatic enrollment:</span> Automatic enrollment is a plan provision which automatically enrolls participants into the retirement plan at a specified pre-tax salary deferral percentage. This can help increase participation, simplify administration, and help employees save for retirement. Participants in the Simply Retirement by Principal® 401(k) plan are automatically enrolled, but can change their contribution details or opt out at any time. This credit is for plans that include the eligible automatic contribution arrangement (EACA) feature only.</p>`;
const businessOwnerRetirementPlansDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*19,500:</span> Up to $30,500 for employees age 50 or older. Amounts are for the 2024 tax year.</p>`;
const businessOwnerRetirementPlansDisclaimer4 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*19,500:</span> Up to $30,500 for employees age 50 or older. Amounts are for the 2024 tax year.</p>`;
const businessOwnerRetirementPlansDisclaimer5 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*19,500:</span> Up to $30,500 for employees age 50 or older. Amounts are for the 2024 tax year.</p>`;
const businessOwnerRetirementPlansDisclaimer6 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*13,500:</span> Up to $19,500 for employees age 50 or older; can’t exceed 100% of compensation. Amount is for the 2024 tax year.</p>`;
const businessOwnerStartupCostsDisclaimer = `<p class="fs-startup-costs-disclaimer">Start-up tax credit modification: Small employers with 50 or fewer employees may apply 100% of qualified start-up costs towards the tax credit formula (up to $5,000 per year). </p><p>New tax credit for start-up plans offering employer contributions: A tax credit equal to the applicable percentage of employer contributions, capped at a maximum of $1,000 per employee.<ul><li>Applicable to small employers with 50 or fewer employees.</li><li>For employees with 51-100 employees: The credit is phased out by reducing the amount of credit each year 2% for each employee in excess of 50.</li></ul><b>Applicable Percentage:</b><p>1st and 2nd year = 100%, 3rd year = 75%, 4th year = 50%, 5th year = 25%, 6th year = 0% </p><p>No contributions may be counted for employees with wages in excess of $100,000 (inflation adjusted). If taking advantage of this tax credit, employer contributions may not also be counted towards “start-up costs” in the start-up tax credit calculation.</p></p>`;
const businessOwnerEligibleContributionDisclaimer = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Eligible automatic contribution arrangement:</span> The SECURE 2.0 Act of 2019 provided an automatic enrollment one-time tax credit possible to be up to $500 per tax year for each year of the 3-taxable-year period beginning with the first taxable year for which the employer includes an eligible automatic contribution arrangement. This credit is for plans that include the eligible automatic contribution arrangement (EACA) feature only. Information about the SECURE Acts is educational only and provided with the understanding that Principal<small><sup>®</sup></small> is not rendering legal, accounting, investment advice or tax advice. You should consult with appropriate counsel or other financial professionals on all matters pertaining to legal, tax, investment or accounting obligations and requirements.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${businessOwnerRetirementPlansDisclaimer1}
     ${businessOwnerRetirementPlansDisclaimer2}
     ${businessOwnerRetirementPlansDisclaimer3}
     ${businessOwnerRetirementPlansDisclaimer4}
     ${businessOwnerRetirementPlansDisclaimer5}
     ${businessOwnerRetirementPlansDisclaimer6}
     ${businessOwnerStartupCostsDisclaimer}
     ${businessOwnerEligibleContributionDisclaimer}
    </div>
  </div>
`;

const RetirementPlans = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'retirement-plans-employer-disclaimer-1',
      'retirement-plans-employer-disclaimer-2',
      'retirement-plans-employer-disclaimer-4',
      'retirement-plans-employer-disclaimer-4-2',
      'retirement-plans-employer-disclaimer-5',
      'retirement-plans-employer-disclaimer-6',
      'retirement-plans-employer-startup-costs-disclaimer',
      'retirement-plans-employer-eligible-contribution',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'retirement-plans-employer-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerRetirementPlansDisclaimer1,
        });
        break;

      case 'retirement-plans-employer-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerRetirementPlansDisclaimer2,
        });
        break;

      case 'retirement-plans-employer-disclaimer-4':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerRetirementPlansDisclaimer3,
        });
        break;

      case 'retirement-plans-employer-disclaimer-4-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerRetirementPlansDisclaimer4,
        });
        break;

      case 'retirement-plans-employer-disclaimer-5':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerRetirementPlansDisclaimer5,
        });
        break;

      case 'retirement-plans-employer-disclaimer-6':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerRetirementPlansDisclaimer6,
        });
        break;

      case 'retirement-plans-employer-startup-costs-disclaimer':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerStartupCostsDisclaimer,
        });
        break;

      case 'retirement-plans-employer-eligible-contribution':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: businessOwnerEligibleContributionDisclaimer,
        });
        break;

      default:
        break;
    }
  };
  return (
    <div className="retirment-plans-page">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />
      <SectionSingleCol
        columns="offset-lg-1 col-lg-9"
        copy={whyConsiderSection}
      />
      <PurpleSection copy={sectionPurple} />
      <Description />
      <TableSection />
      <section className="section section_py-5 bg-iron">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <img
                src={CheckCircle}
                alt="Checkmark In Circle Icon"
                className="d-block mx-auto mb-3"
                width="90"
              />
              <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
              <p className="text-center pb-5 mb-5">
                <Link to="/plans" className="srp-button srp-button__primary">
                  See if our 401(k) plan fits your needs
                </Link>
              </p>
              <p className="fs-disclaimer text-center pb-5 mb-5">
                Intended for plan sponsor use.
              </p>
            </div>
          </div>
        </div>
      </section>
      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
    </div>
  );
};

RetirementPlans.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RetirementPlans);
