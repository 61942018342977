import React from 'react';
import PropTypes from 'prop-types';
import {
  Question,
  checkValidityOnQuestionArray,
  restrictedState,
} from '../../../../../common/FormWithSteps';
import FileUpload from '../../../../../common/FormWithSteps/Question/FileUpload';
import { restrictedStateInsuranceAgent } from '../../../../../common/FormWithSteps/Utilities';

const WarningModal = ({ copy }) => (
  <div className="text-center my-5">
    <h1 className="fs-h1 text-center mb-3">
      Working with a financial professional
    </h1>
    <div
      className="fs-p text-center mb-4 lh-125"
      dangerouslySetInnerHTML={{ __html: copy }}
    />
  </div>
);
WarningModal.propTypes = {
  copy: PropTypes.string.isRequired,
};

const ClientQuestions = ({
  questions,
  role,
  handleQuestionChangeLogo,
  handleQuestionChange,
  updateQuestionById,
  putApplicationPanelInfo,
  advisorInfo,
  createModal,
  formData,
  putInsuranceAgent,
  putInsuranceAgentNpn,
}) => {
  const {
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyName,
    tpaLogo,
    employerStateCode,
    hasFp,
    insuranceGroupType,
  } = questions;
  const { brokerType } =
    formData.planDesign.advisors[0].advisorCredentialVerification;
  const brokerTypeFp = hasFp.value ? brokerType : '';

  const showStateErrorMessage =
    (restrictedState(employerStateCode.value) &&
      !checkValidityOnQuestionArray(advisorInfo)) ||
    restrictedStateInsuranceAgent(employerStateCode.value);

  const stateErrorMessage = () => {
    if (restrictedState(employerStateCode.value)) {
      return 'A financial professional is required for the client to purchase a Simply Retirement by Principal<small><sup>®</sup></small> plan in their state of residence. Your client will not be able to submit the plan for purchase without a financial professional.';
    }
    if (restrictedStateInsuranceAgent(employerStateCode.value) && hasFp.value) {
      return "Be careful, in the state you've selected for your client, if they will not be allowed to continue if they are working with an insurance agent.";
    }
    return '';
  };

  const handleQuestionChangeState = (id, updateValue, valueFormatted) => {
    handleQuestionChange(id, updateValue, valueFormatted);
    updateQuestionById(
      insuranceGroupType.name,
      insuranceGroupType.value,
      updateValue === 'TX' ? !insuranceGroupType.value : false,
      true,
      insuranceGroupType.valueFormatted,
    );
    if (resetFpInsuranceAgent) handleRestrictedInsuranceStateChange();
  };

  const handleRestrictedInsuranceStateChange = () => {
    putInsuranceAgent({ insuranceAgent: '' }, 0);
    putInsuranceAgentNpn({ insuranceAgentNpn: '' }, 0);
  };
  const resetFpInsuranceAgent =
    hasFp.value && brokerTypeFp === 'insuranceAgent' && formData.hashId !== '';

  const requiresFP = () => {
    if (
      restrictedState(employerStateCode.value) &&
      !checkValidityOnQuestionArray(advisorInfo)
    ) {
      hasFp.value = true;
      hasFp.dirty = true;
      return true;
    }
    return false;
  };

  return (
    <div className="row">
      <div className="col-md-8 mx-auto">
        <fieldset>
          <div className="row">
            <div className="col-lg-6">
              <FileUpload
                accept=".jpg,.png,.jpeg"
                label="TPA firm logo (optional)"
                hasError={tpaLogo.hasError}
                updateFilesCb={handleQuestionChangeLogo}
                storedImage={tpaLogo}
              >
                <small>
                  {tpaLogo.hasError && <span>Image is too large</span>}
                  <button
                    type="button"
                    className="srp-button__link"
                    onClick={() =>
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: 'Uploading your logo',
                        additionalInformation: `Your logo will only appear on the proposal, not in the customer portal. Your logo should be in one of the following file formats: PNG, or JPG. The width of the uploaded image should not exceed 500px and the height of the uploaded image should not exceed 500px. The file size should not exceed 500K. By inserting your organization's logo you are indicating that you have received permission to include the organization's logo in this proposal document and hereby grant to Ubiquity Retirement + Savings (“Ubiquity”) and Principal Financial Group, its subsidiaries and affiliates (“Principal”) permission to use the following: 1. The trademarks, service marks and logos of the organization (collectively, “Trademarks”).`,
                      })
                    }
                  >
                    More information
                  </button>
                </small>
              </FileUpload>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md">
              <Question
                role={role}
                type={sponsorFirstName.type}
                labelClass="fs-form-label fw-bold"
                inputClass="srp-form-input form-control"
                question={sponsorFirstName}
                handleQuestionChange={handleQuestionChange}
              >
                {sponsorFirstName.hasError && (
                  <small>Question is required</small>
                )}
              </Question>
            </div>
            <div className="col-md">
              <Question
                role={role}
                type={sponsorLastName.type}
                labelClass="fs-form-label fw-bold"
                inputClass="srp-form-input form-control"
                question={sponsorLastName}
                handleQuestionChange={handleQuestionChange}
              >
                {sponsorLastName.hasError && (
                  <small>Question is required</small>
                )}
              </Question>
            </div>
            <div className="col-md">
              <Question
                role={role}
                type={sponsorEmail.type}
                labelClass="fs-form-label fw-bold"
                inputClass="srp-form-input form-control"
                question={sponsorEmail}
                handleQuestionChange={handleQuestionChange}
              >
                {sponsorEmail.hasError && <small>Question is required</small>}
              </Question>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <Question
                role={role}
                type={companyName.type}
                labelClass="fs-form-label fw-bold"
                inputClass="srp-form-input form-control"
                question={companyName}
                handleQuestionChange={handleQuestionChange}
              >
                {companyName.hasError && <small>Question is required</small>}
              </Question>
            </div>
            <div className="col-md-6">
              <Question
                role={role}
                type={employerStateCode.type}
                labelClass="fs-form-label fw-bold text-center"
                inputClass="srp-form-input form-control"
                question={employerStateCode}
                handleQuestionChange={handleQuestionChangeState}
              >
                {companyName.hasError && <small>Question is required</small>}
              </Question>
              {showStateErrorMessage && (
                <p
                  className="fs-disclaimer lh-125 mt-2 text-danger"
                  dangerouslySetInnerHTML={{ __html: stateErrorMessage() }}
                />
              )}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-6 mx-auto">
              {!checkValidityOnQuestionArray(advisorInfo) && (
                <div className="form-group">
                  <span className="fs-form-label fw-bold">{hasFp.label}</span>
                  <div className="my-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="option1"
                        disabled={requiresFP()}
                        value={hasFp.options[0].value}
                        name={hasFp.options[0].name}
                        checked={hasFp.value === hasFp.options[0].value}
                        onChange={(input) => {
                          createModal(
                            <WarningModal copy="<p>When working with a financial professional, the TPA will complete the plan design sections of Simply Retirement by Principal<sup><small>®</small></sup>.  Upon completion, you'll be asked to send this proposal to the Financial Professional (using their email address) so they can select the investment lineup and choose the compensation they would like to receive.</p> <p>Upon completion of those tasks, you or the financial professional can share the final proposal with the business owner.</p>" />,
                          );
                          handleQuestionChange(
                            hasFp.name,
                            input.target.checked,
                            hasFp.options[0].label,
                          );
                        }}
                      />
                      <label
                        className="form-check-label mr-2"
                        htmlFor="option1"
                      >
                        <strong>Yes</strong>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="option2"
                        disabled={requiresFP()}
                        value={hasFp.options[1].value}
                        name={hasFp.options[1].name}
                        checked={hasFp.value === hasFp.options[1].value}
                        onChange={(input) => {
                          handleQuestionChange(
                            hasFp.name,
                            !input.target.checked,
                            hasFp.options[1].label,
                          );
                        }}
                      />
                      <label
                        className="form-check-label mr-2"
                        htmlFor="option2"
                      >
                        <strong>No</strong>
                      </label>
                    </div>
                    <small>
                      <button
                        type="button"
                        className="srp-button__link"
                        onClick={() =>
                          putApplicationPanelInfo({
                            hasVisibility: true,
                            heading: 'Why are we asking?',
                            additionalInformation: `If your client is working with a financial professional, the financial professional can select one of three Wilshire 3(38) investment option lineups for the plan. Once you have made all other selections, you will be prompted to share the plan design with your client’s financial professional to make the investment lineup selection. The financial professional will share the plan back to you once the investment lineup selection is complete so you can share the plan directly with your client for approval and purchase. A financial professional is required for the client to purchase a Simply Retirement by Principal<sup><small>®</sup></small> plan in Florida, Georgia and Texas.`,
                          })
                        }
                      >
                        Why are we asking?
                      </button>
                    </small>
                  </div>
                </div>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

ClientQuestions.propTypes = {
  questions: PropTypes.object,
  role: PropTypes.string,
  handleQuestionChangeLogo: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  updateQuestionById: PropTypes.func.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  putInsuranceAgent: PropTypes.func.isRequired,
  putInsuranceAgentNpn: PropTypes.func.isRequired,
};

export default ClientQuestions;
