import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';

const TableFluidNotices = ({
  tableHeaders,
  tableRows,
  applicationPanelInfo,
  putApplicationPanelInfo,
}) => {
  const [selectedRow] = useState(tableRows[0].id);
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  return (
    <div className="table-fluid-large mb-5">
      <div className="table-fluid-large__thead">
        {tableHeaders.map((tableHeader) => (
          <div
            key={tableHeader.id}
            className={classNames('table-fluid-large__col', {
              highlight: tableHeader.highlight,
            })}
          >
            <div className="heading">
              <strong dangerouslySetInnerHTML={{ __html: tableHeader.desc }} />
              {tableHeader.moreInfo && (
                <>
                  <button
                    type="button"
                    className="btn btn-link d-block d-print-none mx-auto fs-disclaimer p-0"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: tableHeader.desc,
                        additionalInformation: tableHeader.moreInfo,
                      });
                    }}
                  >
                    More info
                  </button>
                  <div
                    className="d-print-block d-none"
                    dangerouslySetInnerHTML={{
                      __html: tableHeader.moreInfo,
                    }}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {tableRows.map((tableRow) => (
        <div
          id={tableRow.id}
          key={tableRow.id}
          className={classNames('table-fluid-large__row', {
            active: selectedRow === tableRow.id,
          })}
        >
          {tableRow.rowDesc.map((rowDesc, idx) => (
            <div
              key={rowDesc.id}
              className={classNames('table-fluid-large__col', {
                highlight: tableHeaders[idx].highlight,
              })}
            >
              <div className="table-col scope">
                <strong
                  dangerouslySetInnerHTML={{ __html: tableHeaders[idx].desc }}
                />
                {tableHeaders[idx].moreInfo && (
                  <button
                    type="button"
                    className="btn btn-link d-block fs-disclaimer p-0 d-print-none"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: tableHeaders[idx].desc,
                        additionalInformation: tableHeaders[idx].moreInfo,
                      });
                    }}
                  >
                    More info
                  </button>
                )}
              </div>
              <span
                className="table-col value"
                dangerouslySetInnerHTML={{ __html: rowDesc.desc }}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

TableFluidNotices.defaultProps = {
  tableHeaders: [
    {
      id: 1,
      col: 2,
      desc: `Entity`,
    },
    {
      id: 2,
      col: 2,
      desc: `Notice`,
    },
    {
      id: 3,
      col: 2,
      desc: `Additional Information`,
    },
  ],

  tableRows: [
    {
      id: 1,
      header: 'test',
      rowDesc: [
        {
          id: 1,
          desc: `Ubiquity Retirement + Savings<sup>®</sup>`,
          width: `15%`,
        },
        { id: 2, desc: `ERISA 404(a)-5 Fee Disclosure` },
        {
          id: 3,
          desc: `Does not include third-party administrator (TPA) or financial professional fees; however, the $35 distribution/loan administration fee that Ubiquity is paying the TPA will be included.  `,
        },
      ],
    },
    {
      id: 2,
      header: 'test',
      rowDesc: [
        { id: 1, desc: `Ubiquity Retirement + Savings<sup>®</sup>` },
        { id: 2, desc: `QDIA Notice` },
        { id: 3, desc: `-----------` },
      ],
    },
    {
      id: 3,
      header: 'test',
      rowDesc: [
        { id: 1, desc: `Ubiquity Retirement + Savings<sup>®</sup>` },
        { id: 2, desc: `Safe Harbor Notices <em>(if applicable)</em>` },
        { id: 3, desc: `-----------` },
      ],
    },
    {
      id: 4,
      header: 'test',
      rowDesc: [
        { id: 1, desc: `Ubiquity Retirement + Savings<sup>®</sup>` },
        { id: 2, desc: `Auto Enrollment Notice` },
        {
          id: 3,
          desc: `Ubiquity will provide the appropriate plan auto enrollment notice initially and annually thereafter (ACA, QACA or EACA).`,
        },
      ],
    },
    {
      id: 5,
      header: 'test',
      rowDesc: [
        { id: 1, desc: `Ubiquity Retirement + Savings<sup>®</sup>` },
        {
          id: 2,
          desc: `Sarbanes-Oxley Blackout Notices <em>(if applicable)</em>`,
        },
        { id: 3, desc: `-----------` },
      ],
    },
    {
      id: 6,
      header: 'test',
      rowDesc: [
        { id: 1, desc: `Ubiquity Retirement + Savings<sup>®</sup>` },
        {
          id: 2,
          desc: `Summary Plan Description (SPD) or Summary of Material Modifications`,
        },
        {
          id: 3,
          desc: `For unbundled TPA plans, Ubiquity will send the SPDs the TPA has uploaded into their Ubiquity TPA portal.`,
        },
      ],
    },
    {
      id: 7,
      header: 'test',
      rowDesc: [
        { id: 1, desc: `Ubiquity Retirement + Savings<sup>®</sup>` },
        { id: 2, desc: `Privacy Notice` },
        { id: 3, desc: `-----------` },
      ],
    },
  ],
};

TableFluidNotices.propTypes = {
  tableHeaders: PropTypes.array,
  tableRows: PropTypes.array,
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableFluidNotices);
