import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ScrollInToView } from '../../../../common/VisibilitySensor';
import { PrincipalLogo, UbiquityLogo } from '../../../../ImagePaths';

const PurpleSection = ({
  putApplicationPanelInfo,
  TPADisclaimer1,
  TPADisclaimer2,
}) => (
  <section className="section section_py-5 bg-purple">
    <div className="container">
      <div className="row">
        <div className="offset-md-1 order-md-1 order-2 col-lg-5 col-md-5 align-self-center my-5">
          <h3 className="fs-h3 text-white pl-0 mb-3">
            Two experienced brands.<br></br>One seamless solution.
          </h3>
          <p className="fs-p text-white">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            is a collaboration between two companies that have helped thousands
            of small businesses navigate retirement plans successfully. It’s
            backed by more than 75 years of retirement expertise from{' '}
            <button
              type="button"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: TPADisclaimer1,
                })
              }
              className="text-underline text-white p-0 no-border-button"
            >
              Principal
            </button>
            <sup>
              <small>*</small>
            </sup>{' '}
            along with the 20-year track record of Ubiquity Retirement + Savings
            <sup>
              <small>®</small>
            </sup>
            . Once your clients purchase a plan, they&rsquo;ll manage it on the
            same convenient digital platform used by more than
            <button
              type="button"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: TPADisclaimer2,
                })
              }
              className="text-underline text-white p-0 no-border-button"
            >
              10,000 Ubiquity small business customers.
            </button>
            <sup>
              <small>*</small>
            </sup>
          </p>
        </div>
        <div className="order-md-2 order-1 col-md-6">
          <ScrollInToView
            renderItem={(inView) => (
              <>
                <div
                  className={classNames('ven ven--top d-flex m-auto', {
                    active: inView,
                  })}
                >
                  <img
                    className="ven__img img-fluid m-auto"
                    src={PrincipalLogo}
                    alt="Principal logo"
                  />
                </div>
                <div
                  className={classNames('ven ven--btm d-flex m-auto', {
                    active: inView,
                  })}
                >
                  <img
                    className="ven__img img-fluid m-auto"
                    src={UbiquityLogo}
                    alt="Ubiquity Logo"
                  />
                </div>
              </>
            )}
          />
        </div>
      </div>
    </div>
  </section>
);

export default PurpleSection;

PurpleSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  TPADisclaimer1: PropTypes.string,
  TPADisclaimer2: PropTypes.string,
};
