import PrincipalLogo from '../../Images/principal-simply-retirement-logo-stacked.svg';

export const EXPERIENCE_LINKS = {
  default: [
    {
      type: 'navlink',
      label: '',
      imgSrc: PrincipalLogo,
      className: 'img-fluid d-block d-lg-none mx-auto ml-md-3 mb-4 py-md-4',
      style: {
        maxWidth: '120px',
      },
      title: 'Home',
      to: '/',
      action: '',
    },
    {
      type: 'dropdown',
      label: 'About us',
      items: [
        {
          label: 'Why choose us?',
          to: '/why-simply-retirement',
        },
        {
          label: 'Principal® & Ubiquity',
          to: '/about',
        },
      ],
    },
    {
      type: 'dropdown',
      label: 'Retirement plans',
      items: [
        {
          label: 'Learn the basics',
          to: '/learn-the-basics',
          condition: 'notFinancialHpAndNotTpa',
        },
        {
          label: '401(k)',
          to: '/retirement-plans',
        },
        {
          label: 'Investment lineup',
          to: '/investments',
        },
        {
          label: 'Future products',
          action: 'toggleModalVisibility', // This is based on the action you had in the NavList. Adjust as needed.
          description: 'Sign up for updates',
        },
      ],
    },
    {
      type: 'dropdown',
      label: 'Retirement resources',
      items: [
        {
          label: 'Resources',
          to: '/resources',
          conditionLabel: 'isFinancialHp',
          conditionLabelValue: 'Financial Professional Resources',
        },
        {
          label: 'Plan Proposal',
          to: '/plan-proposal',
          condition: 'isFinancialHpOrTpa',
        },
        {
          label: 'Frequently asked questions',
          to: '/faq',
        },
      ],
    },
    {
      type: 'button',
      label: 'Start a plan', // If not define will assign based on user role
      action: 'navigateToPlan',
    },
  ],
  onedigital: [
    {
      type: 'navlink',
      label: '',
      imgSrc: PrincipalLogo,
      className: 'img-fluid d-block d-lg-none mx-auto ml-md-3 mb-4 py-md-4',
      style: {
        maxWidth: '120px',
      },
      title: 'Home',
      to: '/',
      action: '',
    },
    {
      type: 'dropdown',
      label: 'About us',
      items: [
        {
          label: 'Why choose us?',
          to: '/why-simply-retirement',
        },
        {
          label: 'Principal® & Ubiquity',
          to: '/about',
        },
      ],
    },
    {
      type: 'dropdown',
      label: 'Retirement plans',
      items: [
        {
          label: 'Learn the basics',
          to: '/learn-the-basics',
          condition: 'notFinancialHpAndNotTpa',
        },
        {
          label: '401(k)',
          to: '/retirement-plans',
        },
        {
          label: 'Investment lineup',
          to: '/investments',
        },
        {
          label: 'Future products ',
          action: 'toggleModalVisibility', // This is based on the action you had in the NavList. Adjust as needed.
          description: 'Sign up for updates',
        },
      ],
    },
    {
      type: 'navlink',
      label: 'Frequently asked questions',
      to: '/faq',
    },
    {
      type: 'button',
      label: 'Start a plan', // If not define will assign based on user role
      action: 'navigateToPlan',
    },
  ],
};

export default EXPERIENCE_LINKS;
