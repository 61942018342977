import React from 'react';
import { Link } from 'react-router-dom';
import Question from './Question';
import {
  IconDollar,
  IconPiggyBank,
  IconUserGraphO,
  IconMultipleUsers,
  IconRocketBlue,
} from '../../../../ImagePaths';

const QuestionsSet1 = [
  {
    id: 1,
    src: IconUserGraphO,
    anchor: 'features-owner',
    title: `Features for business owners`,
    desc: `
    <div class="row">
      <div class="mb-4 col-md-12"><p><span class="fs-p fw-bold mb-0">Automatic employee enrollment.</span><span class="fs-p"> When you create your client's plan online, you’ll select the eligibility and entry requirements,<sup><small>1</small></sup> and specify if the plan will use auto enrollment. For a print-friendly worksheet that outlines all the options available, <a href="/third-party-administrator/plan-proposal">start a proposal</a>.
        </span></p><p class="fs-disclaimer"><sup><small>1</small></sup>Regardless of whether they meet the plan's age and service requirements, union employees, nonresident aliens, and independent contractors aren't
        allowed in the plan.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Optional automatic contribution increases. </span><span class="fs-p">Business owners can choose to have their employees' contributions remain fixed unless they change them, or auto-escalate 1% each year up to 10%.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Profit-sharing flexibility. </span><span class="fs-p">Business owners have the option to contribute company profits back to employees’ 401(k) plan accounts.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Payroll provider integrations. </span><span class="fs-p">Business owners can save time and reduce errors by automating contribution reporting from their payroll provider system directly into Simply Retirement by Principal<small><sup>®</sup></small>. Ubiquity’s Payroll (K)oncierge service can help them get started.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Protection through an ERISA fidelity bond. </span><span class="fs-p">Business owners will get an ERISA fidelity bond to protect the plan's assets (up to $250,000 in assets, which equals a $25,000 bond).</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Easy-to-use online platform for plan administration. </span><span class="fs-p">Business owners will manage their plan using the intuitive Ubiquity Retirement + Savings<sup><small>®</small> </sup> platform.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Automated signup and onboarding. </span><span class="fs-p">Employees will receive an email from Ubiquity as soon as they’re eligible with instructions to set up a login to their plan account.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Recordkeeping.</span> <span class="fs-p">Services include tracking which employees are participating, the amount they’ve invested, and the amount invested in each of the plan funds.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Dedicated phone number. </span><span class="fs-p">There's a team of people just a phone call away if you or your clients have a question.</p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Owner participation. </span><span class="fs-p">This isn’t just a benefit for employees; qualifying business owners can also participate and maximize their retirement savings with any available matching contributions.</p></div>
    </div>
    `,
  },
  {
    id: 2,
    src: IconMultipleUsers,
    anchor: 'features-employees',
    title: `Features for participating employees`,
    desc: `
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Preset investment options. </span><span class="fs-p">Participants can pick from a carefully selected lineup of investment options. Wilshire Advisors LLC is the 3(38) plan fiduciary. See <a href="/third-party-administrator/investments">investments we offer.</a> If you or your client is working with a financial professional, you’ll be prompted to share the plan you create with the financial professional so they can assist the client in selecting one of the available Wilshire 3(38) investment lineups for the plan. </span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Financial wellness. </span><span class="fs-p">Participants will have access to a comprehensive financial wellness platform that provides tools and resources to employees to better manage their current and future financial well-being.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Roth contributions. </span><span class="fs-p">When you create the plan online, you’ll have the option to select whether to allow participants to make Roth contributions in addition to pre-tax deferrals in the online plan creation. Auto-enrollment contributions can only be pre-tax contributions.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Loans. </span><span class="fs-p">Participants can request to take a loan from their 401(k) plan balance and select a loan repayment schedule that best suits them. Only one loan may be outstanding at a time. Loan repayments are made via after-tax payroll deductions. The interest portion of the loan payment is applied to the participant's account.</span></p></div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Vesting. </span><span class="fs-p">You’ll have the option to select vesting schedules when you create the plan online.</span></p></div>
      <div class="col-md-6 mb-4"><p><span class="fs-p fw-bold mb-0">Rollovers. </span><span class="fs-p">Participants can roll over eligible accounts into their 401(k) plan. (Rollover contributions can be distributed at any time.)</span></p></div>
    </div>
    `,
  },
];

const QuestionsSet2 = [
  {
    id: 1,
    src: IconPiggyBank,
    anchor: 'investments',
    title: `Investments we offer`,
    desc: `
    <h2 class="fs-p fw-bold"><strong>Wilshire Advisors LLC</strong> is the 3(38) investment fiduciary. They have an exceptional track record in selecting high-performing investment options.</h2>
    <p class="fs-p">Wilshire Advisors LLC will provide objective, independent third-party oversight for the screening, selection, and monitoring of the investment options for Simply Retirement by Principal<sup><small>®</small></sup> and will have discretion for making changes when they deem appropriate. Wilshire Advisors LLC is a diversified global financial services firm with more than 40 years of experience providing investment guidance to some of the largest plan sponsors in the U.S. The firm’s core strength is in the use of market-tested manager research techniques that have been refined over four decades serving the institutional and pension consulting marketplace.</p>
    <p class="fs-p">Financial professionals can select one of the available Wilshire 3(38) investment lineups for each plan. Participating employees have the option to choose their mix of investments from the preselected investment lineup. If the business owner is working with a financial professional, the financial professional will have the option to assist the business owner in selecting one of the available Wilshire 3(38) investment lineups for the plan.</p>
    `,
  },
  {
    id: 2,
    src: IconDollar,
    anchor: 'compensation',
    title: `TPA compensation`,
    desc: `
    <h2 class="fs-p fw-bold">You’ll bill the client directly for your fees. You can choose to show your fees on the proposal or plan document to help your clients see the true costs of the plan. You’ll also be able to include the financial professional fees if you’re working with one.</h2>
    `,
  },
];

const QuestionSection = () => (
  <>
    <section className="section section_py-5 pt-5">
      <div className="container">
        <div className="row pb-3">
          <img
            className="mx-auto my-3"
            style={{ width: '84px' }}
            src={IconRocketBlue}
            alt="rocket"
          />
        </div>
        <div className="row justify-content-center pb-5">
          <p className="fs-h3 fw-bold text-center col-lg-6">
            Simply Retirement by Principal
            <sup>
              <small>®</small>
            </sup>{' '}
            401(k) plan features
          </p>
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="row">
              <Question questions={QuestionsSet1} width={50} height={60} />
              <Question questions={QuestionsSet2} width={50} height={60} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section pt-5">
      <div className="container">
        <div className="row">
          <Question questions={QuestionsSet1} width={60} height={80} expand />
        </div>
      </div>
    </section>

    <section className="section pb-5">
      <div className="container">
        <div className="row">
          <Question
            questions={[QuestionsSet2[0]]}
            width={60}
            height={80}
            expand
          />
        </div>
        <div className="row py-5">
          <div className="col-lg-8 mx-auto">
            <div className="text-center">
              <Link
                to="/third-party-administrator/investments"
                className="srp-button srp-button__primary"
              >
                View our investment offerings
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <Question
            questions={[QuestionsSet2[1]]}
            width={60}
            height={80}
            expand
          />
        </div>
      </div>
    </section>
  </>
);

export default QuestionSection;
