import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';

import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import PurpleSection from './PurpleSection';

import {
  AboutHeroImage,
  PrincipalLogo,
  UbiquityLogo,
  CheckCircle,
} from '../../../ImagePaths';

const sectionHeader = {
  image: AboutHeroImage,
  title: 'Backed by Experience',
  caption: `
    <p class="fs-display5">
      Simply Retirement by Principal<small><sup>®</sup></small> combines
      <strong>more than 75 years of Principal<small><sup>®</sup></small> retirement and <button id="about-advisor-disclaimer-1" class="text-underline fs-display5 mx-0 px-0 my-0 py-0 no-border-button">financial expertise</button><small><sup>*</sup></small></strong>
      with the digital 401(k) plan technology of Ubiquity Retirement + Savings<small><sup>&reg;</sup></small>.
    </p>
  `,
  alt: 'Employee at a business meeting.',
  copy: `
  <div class="container-fluid">
  <div class="row my-4">
  <div class="col text-center my-auto">
    <img src=${PrincipalLogo} alt="Principal Logo" class="img-fluid d-block mx-auto" />
     </div><div class="col-auto fs-h1 my-auto text-center">
 +</div>
<div class="col text-center my-auto">   <img src=${UbiquityLogo} alt="Ubiquity Logo" class="img-fluid d-block mx-auto" />
</div>
</div>
</div>
    <p class="fs-h6">
      Together, we’ve created a solution that helps make it simple for financial professionals and their small business clients to set up and manage an affordable 401(k) plan online. It’s our goal to make retirement savings more accessible to
      the millions of small businesses across the nation that don’t offer
      a plan to their employees today.
    </p>`,
};

const principalSection = `
    <img
      src=${PrincipalLogo}
      alt="Principal"
      width="210"
      class="img-fluid d-block mb-5 mx-auto"
    />
    <p class="fs-display5 text-center mb-5">
      <strong>
        With more than 5 million retirement plan <button id="about-advisor-disclaimer-2" class="text-underline p-0 no-border-button fs-display5 fw-bold">participants</button><sup><small>*</small></sup>
      </strong>,
      Principal<small><sup>®</sup></small>
      Principal® is a global investment management and retirement plan service leader that has worked with thousands of small business clients like yours.
    </p>
    <p class="fs-h6">
      <strong>
        We’ve also been named one of the World’s Most Ethical <button id="about-advisor-disclaimer-3" class="text-underline p-0 no-border-button fs-h6 fw-bold">Companies</button><sup><small>*</small></sup>.
      </strong>
      Our offerings are based on experience, not quick wins or fads.
      Because we know that integrity, honesty, and comprehensive expertise are the right way to go—and the surest path to helping your clients reach their long-term financial stability goals. Learn more at
      <a href="https://principal.com" target="_blank">principal.com</a>.
    </p>
`;

const ubiquitySection = `
  <img
    src=${UbiquityLogo}
    width="210"
    alt="Ubiquity"
    class="img-fluid d-block mb-5 mx-auto"
  />
  <p class="fs-h2 text-center mb-5">
    Ubiquity Retirement + Savings<sup><small>®</small></sup> is a leading fintech company that sits at the crossroads of human capital management (HCM), software as a service (SaaS) and Robo-recordkeeping.
  </p>
  <p class="fs-h6">
    With more than <strong>20 years of digital 401(k) plan technology experience</strong>,
    Ubiquity is the perfect complement to the Principal<small><sup>®</sup></small>
    retirement savings <button id="about-advisor-disclaimer-4" class="text-underline p-0 no-border-button fs-h6">product offering</button><sup><small>*</small></sup>. Ubiquity’s cloud-based, proprietary recordkeeping platform backs the company’s retirement solutions. For more
    information, please visit <a href="https://myubiquity.com/about" target="_blank">myubiquity.com</a>.
  </p>
`;

const workTogetherSection = `
  <h3 class="fs-h3 text-center mb-5">What’s in it for you<br /> and your clients</h3>
  <h4 class="fs-h4 text-secondary fw-bold mb-3">
    Why Principal chose Ubiquity Retirement + Savings<sup><small>®</small></sup>
  </h4>
  <p class="fs-p mb-5">
  Delivering the best possible digital retirement solution experience has always been the vision for Simply Retirement by Principal<sup><small>®</small></sup>. That’s why Principal selected Ubiquity. Their proprietary recordkeeping system is a forward-thinking, future-ready solution and it’s a major leap forward for the industry. This system streamlines everything from plan design to onboarding and administration, helping you and your clients save time.
  </p>
  <h4 class="fs-h4 text-secondary fw-bold mb-3">
    What this means
  </h4>
  <p class="fs-p mb-5">
  Your clients may notice the Ubiquity name on some of their plan correspondence and documents, and this just means that Principal and Ubiquity are working together to support them. Both companies are committed to helping you connect your clients with a retirement savings plan that works for them and their employees—so you can recommend a solution that’s backed by two established and recognizable brands.
  </p>
  <h4 class="fs-h4 text-secondary fw-bold mb-3">
    How we keep investment options simple
  </h4>
  <p class="fs-p mb-5">
  Wilshire & Associates, Inc. will be the plan’s 3(38) investment fiduciary. They’ll provide independent third-party oversight for the screening, selection, and monitoring of the plan’s <button id="about-advisor-disclaimer-5" class="text-underline p-0 no-border-button fs-p">investment options.</button><sup><small>*</small></sup> Offering a set of <a href="/financial-professionals/investments">preselected investments</a> helps keep the process as streamlined and cost-effective as possible—so you can focus on serving your clients and spend less time managing investment details.
  </p>
`;

const financialProfessionalAboutDisclaimer1 = `
      <p class="fs-disclaimer-print"><span class="d-print-block d-none">*Financial expertise:</span> As of Dec. 31, 2019</p>`;
const financialProfessionalAboutDisclaimer2 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Participants:</span> Ethisphere Institute, March 2017</p>`;
const financialProfessionalAboutDisclaimer3 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Companies:</span> As of July 2019</p>`;
const financialProfessionalAboutDisclaimer4 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Product Offering:</span> As part of the Simply Retirement by Principal<sup><small>®</small></sup> solution, Wilshire Investments, Inc. is the fiduciary responsible for the selection and monitoring of the investments.</p>`;
const financialProfessionalAboutDisclaimer5 = `<p class="fs-disclaimer-print"><span class="d-print-block d-none">*Investment Options:</span> As part of the Simply Retirement by Principal<sup><small>®</small></sup> solution, Wilshire Investments, Inc. is the fiduciary responsible for the selection and monitoring of the investments.</p>`;
const footNoteList = `
  <div class="row mx-0 my-5">
    <div class="col-12 text-left">
     ${financialProfessionalAboutDisclaimer1}
     ${financialProfessionalAboutDisclaimer2}
     ${financialProfessionalAboutDisclaimer3}
     ${financialProfessionalAboutDisclaimer4}
     ${financialProfessionalAboutDisclaimer5}
    </div>
  </div>
`;

const About = ({ applicationPanelInfo, putApplicationPanelInfo }) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = [
      'about-advisor-disclaimer-1',
      'about-advisor-disclaimer-2',
      'about-advisor-disclaimer-3',
      'about-advisor-disclaimer-4',
      'about-advisor-disclaimer-5',
    ];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'about-advisor-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: financialProfessionalAboutDisclaimer1,
        });
        break;

      case 'about-advisor-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: financialProfessionalAboutDisclaimer2,
        });
        break;

      case 'about-advisor-disclaimer-3':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: financialProfessionalAboutDisclaimer3,
        });
        break;

      case 'about-advisor-disclaimer-4':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: financialProfessionalAboutDisclaimer4,
        });
        break;

      case 'about-advisor-disclaimer-5':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: financialProfessionalAboutDisclaimer5,
        });
        break;

      default:
        break;
    }
  };
  return (
    <div className="about-page mb-5">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />
      <SectionSingleCol copy={principalSection} />
      <SectionSingleCol className="pt-0 index-3" copy={ubiquitySection} />
      <PurpleSection className="mt-5" />
      <SectionSingleCol copy={workTogetherSection} />
      <section className="section section_py-5 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <img
                src={CheckCircle}
                alt="Checkmark In Circle Icon"
                className="d-block mx-auto mb-3"
              />
              <h3 className="fs-h3 text-center mb-4">Let’s get started.</h3>
              <p className="text-center pb-5 mb-5">
                <NavLink
                  to="/plans"
                  className="srp-button srp-button__primary px-4"
                >
                  Create a proposal
                </NavLink>
              </p>
              <p className="fs-disclaimer text-center pb-5 mb-5">
                Intended for financial professional use.
              </p>
              <span className="d-none">1178535-0S2020</span>
            </div>
          </div>
        </div>
      </section>
      <SectionSingleCol className="d-print-block d-none" copy={footNoteList} />
    </div>
  );
};

About.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
